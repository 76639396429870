.trips-page {
  font-family: var(--font-family-base);
  background-color: var(--color-background);
  color: var(--color-text);
  min-width: 1024px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 80px; /* Account for fixed Navbar */
}

.trips-content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  flex-grow: 1;
}

.trips-content h1 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2em;
  color: var(--color-primary);
}

.trips-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .trips-content {
    padding: 10px;
  }

  .trips-list {
    justify-content: center;
  }
}

.trips-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.add-manual-trip-btn {
  padding: 0.75rem 1.5rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.add-manual-trip-btn:hover {
  background-color: #45a049;
}

@media (max-width: 768px) {
  .trips-header {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }
}
