.place-info-popup {
    position: fixed;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 12px;
    z-index: 1000;
    max-width: 300px;
    animation: fadeIn 0.2s ease-in-out;
  }
  
  .popup-loading {
    padding: 10px;
    text-align: center;
    color: #666;
  }
  
  .popup-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .popup-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 4px;
  }
  
  .popup-content h4 {
    margin: 0;
    font-size: 1.1em;
    color: #333;
  }
  
  .popup-rating {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  
  .popup-rating i {
    color: #ffd700;
    font-size: 0.9em;
  }
  
  .popup-info {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 0.9em;
    color: #666;
  }
  
  .popup-info i {
    color: #6200ea;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }