.place-info-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: background-color 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
  }
  
  .place-info-overlay.visible {
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1;
    visibility: visible;
  }
  
  .place-info-content {
    background-color: white;
    border-radius: 16px;
    padding: 16px;
    width: 90%;
    max-width: 500px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    transform: scale(0.95);
    opacity: 0;
    transition: transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1), 
                opacity 0.3s ease;
  }
  
  .place-info-overlay.visible .place-info-content {
    transform: scale(1);
    opacity: 1;
  }
  
  .close-button {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 2;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .close-button:hover {
    background: rgba(255, 255, 255, 1);
  }
  
  .place-info-content h3 {
    margin: 0 0 15px 0;
    color: #333;
    font-size: 1.4em;
    padding-right: 30px;
  }
  
  .rating {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 15px;
    color: #666;
  }
  
  .rating i {
    color: #ffd700;
  }
  
  .review-count {
    color: #888;
    font-size: 0.9em;
  }
  
  .info-row {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 12px;
    color: #666;
  }
  
  .info-row i {
    width: 20px;
    text-align: center;
    color: #6200ea;
  }
  
  .description {
    margin-top: 20px;
    line-height: 1.6;
    color: #444;
  }
  
  .loading, .updating {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #666;
    font-size: 1.1em;
    padding: 20px 0;
  }
  
  .warning {
    margin-top: 20px;
    color: #ff4444;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .warning i {
    color: #ff4444;
  }
  
  .place-info-content a {
    color: #6200ea;
    text-decoration: none;
  }
  
  .place-info-content a:hover {
    text-decoration: underline;
  }
  
  .place-image-container {
    width: 100%;
    max-height: 300px;
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 16px;
    background-color: #f5f5f5;
  }
  
  .place-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .place-image:hover {
    transform: scale(1.05);
  }