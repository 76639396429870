.manual-trip-page {
  font-family: var(--font-family-base);
  background-color: var(--color-background);
  color: var(--color-text);
  min-width: 1024px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 80px;
}

.manual-trip-content {
  width: 100%;
  max-width: none;
  margin: 0;
  padding: 20px;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.manual-trip-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  background-color: white;
  border-radius: var(--border-radius);
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.manual-trip-header h1 {
  margin: 0;
  font-size: 2rem;
  font-family: var(--font-family-heading);
  color: var(--color-primary);
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.mode-toggle {
  display: flex;
  border: 2px solid #6200ea;
  border-radius: 50px;
  overflow: hidden;
}

.mode-toggle button {
  padding: 8px 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 0.9em;
  color: #6200ea;
  transition: background-color 0.2s ease, color 0.2s ease;
  min-width: 100px;
}

.mode-toggle button.active {
  background-color: #6200ea;
  color: white;
}

.mode-toggle button:not(.active):hover {
  background-color: rgba(98, 0, 234, 0.1);
}

.add-line-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
}

.add-line-btn {
  padding: 8px 16px;
  background-color: #6200ea;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-weight: 600;
  font-size: 0.9em;
  transition: background-color 0.2s ease;
  white-space: nowrap;
}

.add-line-btn:hover {
  background-color: #5a00d1;
}

.saving-indicator {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  border-radius: 50px;
  background-color: #f4f0ff;
  border: 1px solid #e3d9ff;
  min-width: 120px;
  height: 32px;
}

.saving-text {
  color: #6200ea;
  font-size: 0.9em;
  font-weight: 500;
  opacity: 0;
  will-change: opacity;
}

@keyframes textAppear {
  0% { opacity: 0; }
  100% { opacity: 0.85; }
}

@keyframes textDisappear {
  0% { opacity: 0.85; }
  100% { opacity: 0; }
}

.saving-indicator .saving-text {
  animation: textDisappear 2s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.saving-indicator.saving .saving-text {
  animation: textAppear 3s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

@media (max-width: 768px) {
  .manual-trip-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    padding: 15px;
  }

  .header-actions {
    flex-direction: column;
    width: 100%;
    gap: 1rem;
  }

  .mode-toggle {
    width: 100%;
  }

  .mode-toggle button {
    flex: 1;
    min-width: 0;
  }

  .add-line-wrapper {
    width: 100%;
  }

  .add-line-btn {
    flex: 1;
  }

  .saving-indicator {
    flex: 1;
    justify-content: center;
  }
}

.table-container {
  flex: 1;
  overflow-x: auto;
  overflow-y: auto;
  background: white;
  border-radius: var(--border-radius);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
}

.column-toggles {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 16px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e2e8f0;
  position: sticky;
  top: 0;
  z-index: 2;
}

.column-toggle {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 20px;
  background-color: white;
  color: #4a5568;
  font-size: 0.85em;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.column-toggle:hover {
  border-color: #6200ea;
  color: #6200ea;
  background-color: #f4f0ff;
}

.column-toggle.active {
  background-color: #6200ea;
  color: white;
  border-color: #6200ea;
}

.toggle-icon {
  font-size: 1em;
  opacity: 0.8;
}

.column-toggle.active .toggle-icon {
  opacity: 1;
}

.activities-table {
  min-width: 100%;
  width: max-content;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.table-header {
  display: grid;
  gap: 1px;
  background-color: #f8f9fa;
  border-bottom: 2px solid #e2e8f0;
  position: sticky;
  top: 48px;
  z-index: 1;
  width: 100%;
}

.table-header div {
  padding: 10px 12px;
  font-weight: 600;
  font-size: 0.9em;
  text-align: left;
  color: #4a5568;
  white-space: nowrap;
  background-color: #f8f9fa;
}

.activity-row {
  display: grid;
  gap: 1px;
  background-color: white;
  border-bottom: 1px solid #edf2f7;
  position: relative;
  padding-left: 24px;
  transition: background-color 0.2s ease;
  width: 100%;
}

.activity-row:hover {
  background-color: #f8f9ff;
}

.activity-cell {
  padding: 8px 12px;
  min-height: 36px;
  display: flex;
  align-items: center;
  background-color: white;
}

.activity-cell input {
  width: 100%;
  padding: 6px 8px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.9rem;
  line-height: 1.2;
  transition: all 0.2s ease;
}

.activity-cell input:hover {
  border-color: #cbd5e0;
}

.activity-cell input:focus {
  outline: none;
  border-color: #6200ea;
  box-shadow: 0 0 0 2px rgba(98, 0, 234, 0.1);
}

.drag-handle {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
  color: #6200ea;
  opacity: 0.5;
  transition: opacity 0.2s ease;
  font-size: 1.2em;
}

.drag-handle:hover {
  opacity: 1;
}

.activity-row.dragging {
  background-color: #f4f0ff;
  border: 2px solid #6200ea;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(98, 0, 234, 0.15);
  z-index: 999;
}

.activity-row.dragging .activity-cell {
  background-color: #f4f0ff;
}

.activity-row.dragging .drag-handle {
  cursor: grabbing;
  opacity: 1;
}

.ghost-row {
  opacity: 0.4;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  border-bottom: 1px dashed #e2e8f0;
}

.ghost-row:hover {
  opacity: 0.7;
  background-color: #f8f9ff;
}

.ghost-text {
  color: #6200ea;
  font-style: italic;
  font-size: 0.85em;
}

.add-row-button {
  position: absolute;
  left: -40px;
  top: 50%;
  transform: translateY(-50%);
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #6200ea;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(98, 0, 234, 0.2);
}

.ghost-row:hover .add-row-button {
  opacity: 1;
  left: -36px;
}

.plus-icon {
  font-size: 20px;
  font-weight: 300;
  line-height: 1;
  transform: translateY(-1px);
}

.add-row-button:hover {
  transform: translateY(-50%) scale(1.1);
  box-shadow: 0 3px 6px rgba(98, 0, 234, 0.3);
}

.title-section {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;
}

.title-section h1 {
  margin: 0;
  font-size: 2rem;
  font-family: var(--font-family-heading);
  color: #6200ea;
  display: inline-flex;
  align-items: center;
}

.title-separator {
  font-size: 2rem;
  color: #6200ea;
  opacity: 0.5;
  margin: 0 0.5rem;
  font-weight: 300;
}

.editable-title {
  margin: 0;
  font-size: 2rem;
  font-family: var(--font-family-heading);
  color: #6200ea;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  padding-right: 1.5rem;
}

.edit-icon {
  position: absolute;
  right: 0;
  font-size: 1.2rem;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.editable-title:hover .edit-icon {
  opacity: 0.7;
}

.title-input {
  margin: 0;
  font-size: 2rem;
  font-family: var(--font-family-heading);
  color: #6200ea;
  border: none;
  border-bottom: 2px solid #6200ea;
  background: transparent;
  padding: 0;
  min-width: 200px;
  outline: none;
}

/* Preview Mode Styles */
.manual-trip-preview {
  padding: 16px;
  max-width: 100%;
  margin: 0 auto;
}

.preview-header {
  text-align: center;
  margin-bottom: 24px;
}

.preview-header h1 {
  font-size: 24px;
  margin-bottom: 8px;
}

.trip-description {
  font-size: 16px;
  color: #666;
  margin-bottom: 16px;
}

.date-selector {
  display: flex;
  overflow-x: auto;
  gap: 8px;
  padding: 8px 0;
  margin-bottom: 24px;
  -webkit-overflow-scrolling: touch;
  justify-content: center;
}

.date-button {
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 20px;
  background: white;
  white-space: nowrap;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 90px;
  text-align: center;
}

.date-button.active {
  background: #6200ea;
  color: white;
  border-color: #6200ea;
}

.date-header {
  font-size: 20px;
  margin-bottom: 16px;
  color: #333;
}

.activity-card {
  background: white;
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.activity-time {
  color: #666;
  font-size: 14px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.activity-time .time {
  display: flex;
  align-items: center;
  gap: 6px;
}

.activity-time i {
  font-size: 17.5px;
}

.activity-title {
  font-size: 18px;
  margin-bottom: 8px;
  color: #333;
}

.location {
  font-size: 14px;
  color: #666;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.notes {
  font-size: 14px;
  color: #666;
  margin-bottom: 12px;
  line-height: 1.4;
}

.note01, .note02 {
  font-style: italic;
  padding-left: 8px;
  border-left: 2px solid #6200ea;
  margin-top: 8px;
}

.activity-links {
  display: flex;
  gap: 16px;
  margin-top: 12px;
}

.icon-link {
  color: #6200ea;
  font-size: 25px;
  transition: color 0.3s ease;
}

.icon-link:hover {
  color: #3700b3;
}

/* Desktop Styles */
@media (min-width: 768px) {
  .manual-trip-preview {
    max-width: 800px;
    padding: 32px;
  }

  .preview-header h1 {
    font-size: 32px;
  }

  .activity-card {
    display: flex;
    gap: 24px;
  }

  .activity-time {
    min-width: 100px;
  }

  .activity-content {
    flex: 1;
  }
}

/* Loading Styles */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #6200ea;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.address, .working-hours {
  font-size: 14px;
  color: #666;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.address i, .working-hours i {
  color: #6200ea;
  font-size: 17.5px;
  width: 20px;
  text-align: center;
}

.preview-header .destination {
  font-size: 28px;
  font-weight: 600;
  margin: 8px 0 16px;
  color: #333;
}

@media (min-width: 768px) {
  .preview-header .destination {
    font-size: 36px;
  }
}

/* Add these styles in the icon-link section */
.icon-link .fa-person,
.icon-link .fa-person-dress {
  color: #6200ea;
  transition: color 0.3s ease;
}

.icon-link:hover .fa-person,
.icon-link:hover .fa-person-dress {
  color: #3700b3;
}

@media (max-width: 768px) {
  .manual-trip-page {
    min-width: auto;
    width: 100%;
  }

  .table-container {
    overflow-x: auto;
    padding-bottom: 16px;
  }

  .column-toggles {
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 12px;
    gap: 6px;
    -webkit-overflow-scrolling: touch;
  }

  .column-toggle {
    padding: 4px 8px;
    font-size: 0.8em;
  }

  .column-toggle.mobile-locked {
    opacity: 0.7;
    cursor: not-allowed;
  }

  .activities-table {
    width: max-content;
    min-width: 100%;
  }

  .table-header,
  .activity-row {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }

  .activity-cell {
    min-width: 120px;
  }

  .activity-cell input {
    font-size: 0.85em;
    padding: 4px 6px;
  }

  .ghost-row {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }

  .add-row-button {
    left: -28px;
  }
}

.activity-time i {
  font-size: 17.5px;
  margin-right: 6px;
}

.location i {
  font-size: 17.5px;
}