.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  height: 80px;
  display: flex;
  align-items: center; /* Center content vertically */
  font-family: var(--font-family-base);
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.navbar-brand {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.navbar-logo {
  text-decoration: none;
  color: inherit;
}

.navbar-logo h1 {
  margin: 0 0 8px 0;
  font-size: 1.5rem;
  color: #333;
}

.navbar-logo p {
  margin: 0;
  font-size: 0.8rem;
  color: #666;
}

.navbar-links {
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.nav-link {
  margin-left: 1.5rem;
  text-decoration: none;
  color: #333;
  font-weight: 500;
  position: relative;
  cursor: pointer;
}

.nav-link-text {
  position: relative;
}

.nav-link-text::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: #6200ea;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.nav-link:hover .nav-link-text::after {
  transform: scaleX(1);
}

.nav-link:hover .nav-link-text {
  color: #6200ea;
}

.button-link {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  padding: 0;
  color: #333;
  transition: color 0.3s ease;
}

.button-link:hover {
  color: #6200ea;
}

.mobile-menu-button {
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
}

.mobile-menu-button:focus {
  outline: 2px solid #6200ea;
  outline-offset: 2px;
}

.navbar-scrolled {
  background-color: rgba(255, 255, 255, 0.95);
}

.navbar-scrolled .nav-link {
  color: #333;
}

.navbar-scrolled .nav-link:hover {
  color: #6200ea;
}

.navbar-scrolled .navbar-logo h1 {
  color: #333;
}

.navbar-scrolled .navbar-logo p {
  color: #666;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .navbar-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.95);
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .navbar-links.active {
    display: flex;
  }

  .nav-link {
    margin: 0.5rem 0;
    width: 100%;
    text-align: center;
  }

  .mobile-menu-button {
    display: block;
  }
}
