.travel-plan-page {
  font-family: var(--font-family-base);
  background-color: var(--color-background);
  color: var(--color-text);
  min-width: 1024px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  
  padding-top: 80px; /* Add padding to account for the fixed Navbar */
}

.travel-plan-page .travel-plan-content {
  width: 100%;
  max-width: none;
  margin: 0;
  padding: 20px;
  flex: 1 0 auto; /* Change from flex-grow: 1 to flex: 1 0 auto */
  display: flex;
  flex-direction: column;
}

.travel-plan-page .top-section,
.travel-plan-page .bottom-section {
  display: flex;
  gap: 20px;
  align-items: flex-start; /* Add this to align items to the top */
}

.travel-plan-page .left-column,
.travel-plan-page .right-column {
  display: flex;
  flex-direction: column;
}

.travel-plan-page .left-column {
  width: 66%;
  flex: 0 0 66%;
  max-width: 66%;
  align-self: flex-start; /* Add this to ensure the column aligns to the top */
}

.travel-plan-page .right-column {
  width: 34%;
  flex: 0 0 34%;
  max-width: 34%;
  display: flex;
  flex-direction: column;
  align-self: flex-start; /* Add this to ensure the column aligns to the top */
}

/* Remove position: sticky to prevent misalignment */
.travel-plan-page .right-column-content {
  background-color: #fff;
  border-radius: 16px;
  padding: 30px;
  margin-bottom: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  
  /* Ensure visibility based on toggle */
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

/* New styles for Customize Header */
.travel-plan-page .customize-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.travel-plan-page .toggle-icon {
  transition: transform 0.3s ease;
}

/* Hide toggle icon on desktop */
@media (min-width: 769px) {
  .travel-plan-page .toggle-icon {
    display: none;
  }
}

/* Rotate toggle icon when open */
.travel-plan-page .right-column-content.open .toggle-icon {
  transform: rotate(180deg);
}

.travel-plan-page .plan-options.hidden {
  display: none;
}

.travel-plan-page .plan-options.visible {
  display: flex;
}

.travel-plan-page .destination-image {
  height: 240px;
  border-radius: var(--border-radius);
  position: relative;
  display: flex;
  align-items: flex-end;
  padding: 20px;
  background-size: 400px 400px; /* Adjust this value based on the actual size of your pattern */
  background-repeat: repeat;
  --bg-position-x: 0px; /* New variable for X position */
  --bg-position-y: 0px; /* New variable for Y position */
  background-position: var(--bg-position-x) var(--bg-position-y);
}

.travel-plan-page .destination-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.5) 100%);
  border-radius: var(--border-radius);
}

.travel-plan-page .destination-image h1 {
  position: relative;
  z-index: 1; 
  color: white;
  font-family: var(--font-family-heading);
  font-size: 2.5em;
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  display: flex;
  flex-direction: column;
}

.travel-plan-page .destination-image .destination-country {
  font-size: 0.7em;
  margin-top: 5px;
}

.travel-plan-page .daily-plan {
  background-color: white;
  border-radius: var(--border-radius);
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  flex-grow: 1;
  overflow-y: auto;
  width: 100%;
  text-align: center; /* Ensure it takes full width of its container */
}

.travel-plan-page .day-plan {
  background-color: white;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #e0e0e0;
  width: 100%; /* Ensure each day plan takes full width */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.travel-plan-page .day-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 100%; /* Ensure the header takes full width */
}

.travel-plan-page .day-title-weather {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  min-width: 0;
  margin-right: 10px; /* Add some space between title/weather and expand button */
}

.travel-plan-page .day-title {
  font-size: 1.2em;
  font-weight: bold;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.travel-plan-page .day-weather {
  font-size: 0.9em;
  color: #666;
  margin-left: 10px;
  white-space: nowrap;
}

.travel-plan-page .expand-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--color-primary);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;  /* Increased from 24px */
  height: 32px; /* Increased from 24px */
  flex-shrink: 0;
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.travel-plan-page .expand-btn i {
  font-size: 1.2em; /* Make the icon itself bigger */
}

/* Add rotation for expanded state */
.travel-plan-page .expand-btn.expanded {
  transform: rotate(180deg);
}

/* Remove any existing hover effects if they don't match the design */
.travel-plan-page .expand-btn:hover {
  background: none;
}

.travel-plan-page .day-description {
  white-space: nowrap; /* Change to nowrap to enable text-overflow */
  overflow: hidden;
  text-overflow: ellipsis; /* Add ellipsis for truncated text */
  transition: all 0.3s ease-out;
  max-height: 1.5em;
  opacity: 1;
  color: #666;
  font-size: 0.9em;
  margin-top: 5px;
  text-align: left;
  width: 100%;
}

.travel-plan-page .day-description.expanded {
  white-space: normal; /* Allow text to wrap when expanded */
  max-height: 1000px;
  text-align: left;
}

/* Add a subtle gradient fade effect for non-expanded state */
.travel-plan-page .day-description:not(.expanded) {
  position: relative;
}

.travel-plan-page .day-description:not(.expanded)::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 50px;
  background: linear-gradient(to right, transparent, white);
  pointer-events: none;
}

.travel-plan-page .day-separator {
  height: 1px;
  background-color: #e0e0e0;
  margin: 10px 0;
}

.travel-plan-page .logistics {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  margin: 10px 0;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.travel-plan-page .logistics-option {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9em;
  color: #666;
  padding: 0 10px;
}

.travel-plan-page .logistics-option:not(:last-child)::after {
  content: '|';
  margin-left: 10px;
  color: #e0e0e0;
}

.travel-plan-page .logistics-option i {
  margin-right: 5px;
  width: 20px;
  text-align: center;
}

.travel-plan-page .day-budget {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 0.9em;
  color: #666;
}

.travel-plan-page .day-activities {
  margin-top: 15px;
}

.travel-plan-page .activity {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  background-color: #f8f9fa;
  padding: 12px 15px;
  border-radius: var(--border-radius);
  text-align: left;
  position: relative; /* Add this */
  cursor: grab;
  padding-right: 90px; /* Increased from 80px */
}

/* Add new styles for the activity number */
.travel-plan-page .activity-number {
  position: absolute;
  right: -12px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  background-color: #6200ea;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8em;
  font-weight: 600;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  transition: all 0.2s ease;
}

/* Add drag handle dots */
.travel-plan-page .drag-handle {
  position: absolute;
  right: 25px; /* Adjusted to accommodate larger info button */
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 2px;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.travel-plan-page .drag-handle::before,
.travel-plan-page .drag-handle::after {
  content: '•';
  color: #6200ea;
  font-size: 14px;
  line-height: 1;
}

/* Show drag handle on hover */
.travel-plan-page .activity:hover .drag-handle {
  opacity: 0.7;
}

/* Style for when being dragged */
.travel-plan-page .activity.dragging {
  background-color: #f0f0f5;
  transform: scale(1.02);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.travel-plan-page .activity.dragging .activity-number {
  transform: translateY(-50%) scale(1.1);
  background-color: #5a00d1;
}

/* Add hover effect */
.travel-plan-page .activity:hover {
  background-color: #f0f0f5;
  transform: translateX(-5px);
  transition: all 0.2s ease;
}

.travel-plan-page .activity:hover .activity-number {
  background-color: #5a00d1;
  transform: translateY(-50%) scale(1.1);
  transition: all 0.2s ease;
}

/* Adjust for mobile */
@media (max-width: 768px) {
  .travel-plan-page .activity-number {
    right: 10px;
    width: 20px;
    height: 20px;
    font-size: 0.75em;
  }
  
  .travel-plan-page .activity {
    padding-right: 40px; /* Make room for the number */
  }
}

.travel-plan-page .activity i {
  margin-right: 15px;
  width: 20px;
  text-align: center;
}

/* Update time layout styles */
.travel-plan-page .activity-time {
  display: flex;
  flex-direction: column;
  align-items: center; /* Change from flex-start to center */
  margin-right: 15px;
  min-width: 60px;
}

.travel-plan-page .activity-time-main {
  font-weight: 500;
  font-size: 0.9em;
  line-height: 1.2;
  text-align: center; /* Ensure time is centered */
}

.travel-plan-page .activity-time-period {
  font-size: 0.8em;
  color: #666;
  line-height: 1;
  text-align: center; /* Ensure AM/PM is centered */
}

.travel-plan-page .activity-description {
  flex: 1;
  line-height: 1.2; /* Match the line-height of time */
  padding-top: 0; /* Remove top padding */
}

.travel-plan-page .plan-options {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  overflow-y: auto;
  padding: 0 20px;
  width: 100%;
}

.travel-plan-page .plan-options h3 {
  text-align: center;
  margin-top: 0;
  margin-bottom: 25px;
  font-family: 'Poppins', sans-serif;
  font-size: 1.5em;
  color: #222;
  font-weight: 700; 
}

.travel-plan-page .option-group {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 300px;
}

.travel-plan-page .option-group label {
  flex: 0 0 30%;
  text-align: left;
  margin-right: 10px;
  font-weight: 500;
  color: #444;
}

.travel-plan-page .option-group input,
.travel-plan-page .option-group select {
  flex: 1;
  padding: 2px 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: white;
  text-align: center;

  font-size: 1em;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.travel-plan-page .option-group input:focus,
.travel-plan-page .option-group select:focus {
  outline: none;
  border-color: #6200ea;
  box-shadow: 0 0 0 2px rgba(98, 0, 234, 0.2);
}

.travel-plan-page .action-buttons {
  display: flex;
  justify-content: space-between; /* Changed from center to space-between */
  margin-top: 30px;
  gap: 10px;
  width: 100%;
}

/* Base styles for action buttons */
.travel-plan-page .action-btn {
  flex: 1;
  min-width: 100px;
  padding: 12px 15px;
  border: 2px solid #6200ea;
  border-radius: 50px;
  cursor: pointer;
  font-weight: 600;
  font-size: 0.9em;
  transition: all 0.3s ease;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Style for filled button (Save/Edit) */
.travel-plan-page .save-edit-btn {
  background-color: #6200ea;
  color: #fff;
  border: 2px solid #6200ea;  /* Add border to maintain button size during hover */
}

.travel-plan-page .save-edit-btn:hover {
  background-color: #fff;
  color: #6200ea;
  border-color: #6200ea;
}

/* Style for outlined buttons (Try again, Print) */
.travel-plan-page .try-again-btn,
.travel-plan-page .print-btn {
  background-color: #fff;
  color: #6200ea;
}

.travel-plan-page .try-again-btn:hover,
.travel-plan-page .print-btn:hover {
  background-color: #6200ea;
  color: #fff;
}

/* Style for the PDF icon */
.travel-plan-page .print-btn i {
  margin-right: 6px;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .travel-plan-page .action-buttons {
    flex-direction: row; /* Keep buttons in a row */
    gap: 8px; /* Reduce gap on mobile */
  }

  .travel-plan-page .action-btn {
    padding: 10px;
    font-size: 0.8em;
    min-width: 80px; /* Smaller minimum width on mobile */
  }
}

/* Hide action-buttons on mobile */
@media (max-width: 768px) {

}

.travel-plan-page .map-container {
  flex-grow: 1;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius);
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.travel-plan-page .bottom-section {
  display: flex;
  gap: 20px;
  flex-grow: 1; /* Ensure bottom section fills available space */
}

.travel-plan-page .steps-count {
  font-size: 0.9em;
  color: #888;
  font-style: italic;
}

@media (max-width: 768px) {
  .travel-plan-page {
    /* Override min-width for mobile devices */
    min-width: auto;
    
    /* Alternatively, you can remove the min-width */
    /* min-width: unset; */
    
    /* Ensure full width */
    width: 100%;
    padding: 10px;
    flex-direction: column;
  }


  .travel-plan-page .travel-plan-content {
    padding: 10px;
    flex-direction: column;
  }

  /* Ensure top-section and bottom-section stack vertically */
  .travel-plan-page .top-section,
  .travel-plan-page .bottom-section {
    flex-direction: column;
    gap: 15px;
  }

  /* Full-width columns */
  .travel-plan-page .left-column,
  .travel-plan-page .right-column {
    width: 100%;
    max-width: 100%;
  }

  /* Adjust destination image */
  .travel-plan-page .destination-image {
    height: 200px;
    background-size: 300px 300px; /* Adjust this value based on the actual size of your pattern */
    background-repeat: repeat;
    --bg-position-x: 0px; /* New variable for X position */
    --bg-position-y: 0px; /* New variable for Y position */
    background-position: var(--bg-position-x) var(--bg-position-y);
  }

  .travel-plan-page .destination-image h1 {
    font-size: 1.8em;
    text-align: left;
    padding: 0 10px;
    align-items: flex-start;
  }

  .travel-plan-page .destination-image .destination-country {
    font-size: 0.7em;
    margin-top: 8px;
  }

  /* Customize Your Plan Section */
  .travel-plan-page .plan-options {
    flex-direction: column;
    align-items: stretch;
    padding: 10px 0;
  }

  .travel-plan-page .option-group {
    flex-direction: column;
    align-items: stretch;
    max-width: 100%;
  }

  .travel-plan-page .option-group label {
    margin-bottom: 5px;
    text-align: left;
  }

  .travel-plan-page .option-group input,
  .travel-plan-page .option-group select {
    width: 100%;
    text-align: left;
  }

  /* Daily Itinerary Section */
  .travel-plan-page .daily-plan {
    padding: 15px;
  }

  .travel-plan-page .day-plan {
    padding: 10px;
  }

  /* Map Section */
  .travel-plan-page .map-container {
    height: 300px;
    margin-top: 20px;
  }

  /* Action Buttons already hidden via global @media above */

  /* Additional Adjustments for Enhanced Mobile Experience */
  .travel-plan-page .logistics {
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    gap: 10px; /* Increase gap between items */
    padding: 15px 0; /* Add more vertical padding */
  }

  .travel-plan-page .logistics-option {
    width: 100%;
    justify-content: center; /* Center content */
    padding: 5px 0; /* Add some vertical padding */
  }

  /* Remove the divider on mobile */
  .travel-plan-page .logistics-option:not(:last-child)::after {
    display: none;
  }

  .travel-plan-page .day-description {
    text-align: left;
  }

  /* Hide Customize Options by Default on Mobile */
  .travel-plan-page .plan-options.hidden {
    display: none;
  }

  .travel-plan-page .plan-options.visible {
    display: flex;
  }

  /* Rotate Toggle Icon when Open */
  .travel-plan-page .toggle-icon {
    transform: rotate(0deg);
    transition: transform 0.3s ease;
  }

  .travel-plan-page .right-column-content.open .toggle-icon {
    transform: rotate(180deg);
  }

  /* Show Plan Options based on state */
  .travel-plan-page .plan-options {
    display: none;
  }

  .travel-plan-page .plan-options.visible {
    display: flex;
  }

  /* Adjust Customize Header for Better Touch Targets */
  .travel-plan-page .customize-header {
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;
  }

  /* Ensure Customize Your Plan section is always open on mobile if needed */
  /* Optional: If you want it to be open by default, you can set .right-column-content to have 'open' class */

  /* Hide action buttons when customize section is closed */
  .travel-plan-page .right-column-content:not(.open) .action-buttons {
    display: none;
  }

  /* Show action buttons when customize section is open */
  .travel-plan-page .right-column-content.open .action-buttons {
    display: flex;
  }
}

/* Always show Customize Your Plan on desktop */
@media (min-width: 769px) {
  .travel-plan-page .right-column-content {
    /* Ensure the section is open on desktop */
    max-height: none !important;
    opacity: 1 !important;
  }

  .travel-plan-page .plan-options {
    display: flex !important;
  }

  /* Hide toggle icon on desktop */
  .travel-plan-page .toggle-icon {
    display: none;
  }

  /* Ensure action-buttons are visible on desktop */
  .travel-plan-page .action-buttons {
    display: flex;
  }

  /* Ensure action buttons are always visible on desktop */
  .travel-plan-page .right-column-content .action-buttons {
    display: flex !important;
  }

  .travel-plan-page .logistics-option:not(:last-child)::after {
    content: '|';
    margin-left: 10px;
    color: #e0e0e0;
  }
}

.travel-plan-page h2 {
  font-size: 2em;
  color: #333;
}

.travel-plan-page .print-btn {
  flex: 0 1 auto;
  padding: 12px 20px;
  border: 2px solid #6200ea;
  border-radius: 50px;
  cursor: pointer;
  font-weight: 600;
  font-size: 0.9em;
  transition: background-color 0.3s ease, color 0.3s ease;
  white-space: nowrap;
  text-align: center;
  min-width: 120px;
  background-color: #fff;
  color: #6200ea;
}

.travel-plan-page .print-btn:hover {
  background-color: #5a00d1;
  color: #fff;
}

.travel-plan-page .print-btn i {
  margin-right: 8px;
}

/* PDF-specific styles */
.travel-plan-page .pdf-content {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 210mm; /* A4 width */
}

.travel-plan-page .pdf-day {
  margin-bottom: 20px;
  page-break-inside: avoid;
}

.travel-plan-page .pdf-activity {
  margin: 10px 0;
  padding: 5px 0;
}

.travel-plan-page .pdf-logistics {
  margin-left: 20px;
  color: #666;
  font-size: 0.9em;
}

/* Adjust padding-top for mobile */
@media (max-width: 768px) {
  .travel-plan-page {
    padding-top: 0; /* Remove top padding on mobile */
  }

  .travel-plan-page .travel-plan-content {
    padding-top: 0; /* Remove top padding from content */
  }

  /* Ensure destination image is positioned correctly */
  .travel-plan-page .destination-image {
    margin-top: 60px; /* Add margin to account for navbar height */
  }
}

/* Keep desktop layout unchanged */
@media (min-width: 769px) {
  .travel-plan-page {
    padding-top: 80px; /* Keep original padding for desktop */
  }
}

/* Add styles for day loading animations */
.day-loading {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

.day-loading span {
  color: #555;
  font-size: 0.9em;
}

.additional-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.additional-loading span {
  color: #555;
  font-size: 0.9em;
}

/* Add styles for footer positioning */
.travel-plan-page .site-footer {
  flex-shrink: 0; /* Prevent footer from shrinking */
  margin-top: auto; /* Push footer to bottom when content is short */
}

/* Update mobile styles */
@media (max-width: 768px) {
  .travel-plan-page {
    min-width: auto;
    width: 100%;
    padding: 0; /* Remove padding-top for mobile */
  }

  .travel-plan-page .travel-plan-content {
    padding: 10px;
    padding-top: 60px; /* Add padding-top for mobile to account for navbar */
  }

  /* Ensure footer displays correctly on mobile */
  .travel-plan-page .site-footer {
    width: 100%;
    margin-top: 20px; /* Add some space between content and footer */
  }
}

/* Update right-column-content styles */
.travel-plan-page .right-column-content {
  background-color: #fff;
  border-radius: 16px;
  padding: 30px;
  margin-bottom: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
}

/* Mobile styles */
@media (max-width: 768px) {
  .travel-plan-page .right-column-content {
    padding: 15px 20px; /* Reduced padding for mobile */
  }

  .travel-plan-page .customize-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 30px; /* Ensure consistent height */
  }

  .travel-plan-page .customize-header h3 {
    margin: 0; /* Remove default margins */
    font-size: 18px; /* Adjust font size if needed */
  }

  .travel-plan-page .right-column-content:not(.open) {
    max-height: 60px;
    overflow: hidden;
  }

  .travel-plan-page .right-column-content.open {
    max-height: 1000px;
  }

  /* Hide content below header when closed */
  .travel-plan-page .right-column-content:not(.open) > *:not(.customize-header) {
    display: none;
  }
}

/* Desktop styles */
@media (min-width: 769px) {
  .travel-plan-page .customize-header {
    cursor: default; /* Remove pointer cursor on desktop */
  }

  .travel-plan-page .right-column-content {
    /* Always show full content on desktop */
    max-height: none !important;
  }

  .travel-plan-page .plan-options {
    display: flex !important;
  }
}

/* Style for when an activity is being dragged */
.activity[aria-roledescription='Draggable'] {
  transition: background-color 0.2s ease;
}

.activity:hover {
  background-color: #f9f9f9;
}

/* Add these new styles */
.travel-plan-page .info-button {
  position: absolute;
  right: 45px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #6200ea;
  padding: 8px;
  opacity: 0.7;
  transition: opacity 0.2s ease, transform 0.2s ease;
  font-size: 1.0em;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.travel-plan-page .info-button:hover {
  opacity: 1;
  transform: translateY(-50%) scale(1.1);
}

.travel-plan-page .activity:hover .info-button {
  opacity: 0.9;
}

.activity-description b[data-is-place="true"] {
  cursor: pointer;
  color: #6200ea;
  transition: color 0.2s ease;
  position: relative;
  z-index: 1;
}

.activity-description b[data-is-place="true"]:hover {
  color: #3700b3;
  text-decoration: underline;
}

/* Add a new class for the popup container */
.place-info-popup-container {
  position: fixed;
  z-index: 1100; /* Higher than other elements */
  pointer-events: none; /* Allow clicking through when transitioning */
}

.place-info-popup-container.active {
  pointer-events: auto; /* Re-enable interactions when active */
}

/* Add transition for smooth appearance */
.place-info-popup {
  transform-origin: top left;
  transition: transform 0.2s ease, opacity 0.2s ease;
}

