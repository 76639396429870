.trip-card-link {
  text-decoration: none;
  color: inherit;
}

.trip-card {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 225px;
  display: flex;
  flex-direction: column;
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
  border: 1px solid transparent;
}

.trip-card:hover {
  box-shadow: 0 4px 8px rgba(98, 0, 234, 0.15);
  border-color: #6200ea;
}

.trip-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.trip-details {
  padding: 15px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.trip-details h2 {
  margin: 0 0 10px 0;
  font-size: 1.5em;
  color: var(--color-primary);
}

.trip-details p {
  margin: 5px 0;
  color: #555;
}

.edit-button {
  margin-top: auto;
  padding: 10px 15px;
  background-color: #6200ea;
  color: #fff;
  border: 2px solid #6200ea;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.edit-button:hover {
  background-color: #fff;
  color: #6200ea;
  border-color: #6200ea;
}

.edit-button:active {
  transform: translateY(0);
}
