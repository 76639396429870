.not-found-page {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.not-found-message {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-size: 24px;
  font-family: 'Poppins', sans-serif;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.7);
  text-align: center;
  z-index: 2; /* Ensure the message is above the game */
}

.flappy-bird-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent; /* Remove or make transparent to see background */
  position: relative;
  width: 100%;
  height: 100vh;
}

canvas {
  border: 2px solid #000;
  background-color: #add8e6; /* Light blue to match the sky */
  image-rendering: pixelated;
}

.restart-button {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: #6200ea;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  z-index: 2;
  transition: background-color 0.3s ease;
}

.restart-button:hover {
  background-color: #4500b5;
}
