.loading-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #fafafa;
  font-family: 'Inter', sans-serif;
  color: #333;
  text-align: center;
  padding: 0 20px;
}

.loading-page h1 {
  font-size: 2em;
  margin-bottom: 8px;
  color: #6200ea;
}

/* Add styles for the refresh warning */
.refresh-warning {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 32px;
}

.animation-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fact-container {
  margin-bottom: 20px;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fun-fact {
  font-size: 1.2em;
  font-style: italic;
  color: #4a4a4a;
  text-align: center;
  max-width: 600px;
}

.show {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.hide {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}
