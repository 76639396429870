:root {
  --color-primary: #6200ea;
  --color-primary-dark: #5a00d1;
  --color-background: #fafafa;
  --color-text: #333;
  --color-text-light: #555;
  --font-family-base: 'Inter', sans-serif;
  --font-family-heading: 'Poppins', sans-serif;
  --border-radius: 8px;
}

