.challenge-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Inter', sans-serif;
  background-color: #fafafa;
}

.challenge-content {
  flex: 1;
  max-width: 1200px;
  margin: 100px auto 40px;
  padding: 0 20px;
  width: 100%;
}

/* Welcome Section */
.welcome-section {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  padding: 40px;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.welcome-section h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 3em;
  margin-bottom: 20px;
  color: #222;
  font-weight: 700;
}

.welcome-section p {
  font-size: 1.15em;
  margin-bottom: 30px;
  color: #555;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.prize-info {
  background-color: #f0e6ff;
  border-radius: 12px;
  padding: 20px;
  margin: 30px auto;
  max-width: 600px;
}

.prize-info p {
  color: #4a148c;
  font-size: 1.1em;
  margin-bottom: 15px;
}

.prize-info p:last-child {
  margin-bottom: 0;
}

/* Register Section */
.register-section {
  max-width: 600px;
  margin: 0 auto;
  padding: 40px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
}

.register-section button {
  margin-top: 20px;
}

.register-section h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 2em;
  margin-bottom: 30px;
  color: #222;
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

.form-group input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1em;
  height: 48px;
}

.form-group input.error-shake {
  animation: shake 0.5s;
  border-color: #ff4444;
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-10px); }
  75% { transform: translateX(10px); }
}

/* Game Section */
.game-section {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  padding: 40px;
  max-width: 800px;
  margin: 0 auto;
}

.game-section h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 2em;
  margin-bottom: 20px;
  color: #222;
  text-align: center;
}

.game-section textarea {
  width: 100%;
  min-height: 120px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1em;
  margin-bottom: 20px;
  resize: vertical;
}

.generated-image {
  max-width: 600px;
  margin: 30px auto 0;
}

.generated-image img {
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.generated-image p {
  color: #666;
  margin-bottom: 20px;
  font-style: italic;
}

.action-buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
}

/* Loading Section */
.loading-section {
  display: none;
}

.loading-section h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #222;
}

.animation-container {
  margin: 20px auto;
  display: flex;
  justify-content: center;
}

/* Complete Section */
.complete-section {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  padding: 40px;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.complete-section h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #222;
}

.complete-section p {
  font-size: 1.15em;
  margin-bottom: 20px;
  color: #555;
}

.complete-section a {
  color: #6200ea;
  text-decoration: none;
  font-weight: 500;
}

.complete-section a:hover {
  text-decoration: underline;
}

/* Buttons */
.cta-button,
.generate-button,
.try-again-button,
.submit-button {
  background-color: #6200ea;
  color: #fff;
  padding: 14px 28px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 1.1em;
  font-weight: 600;
  transition: all 0.3s ease;
  min-width: 200px;
}

.try-again-button {
  background-color: #555;
}

.try-again-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.error-message {
  color: #ff4444;
  margin-top: 10px;
  text-align: center;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .welcome-section h1 {
    font-size: 2em;
  }

  .action-buttons {
    flex-direction: column;
    gap: 10px;
  }

  .cta-button,
  .generate-button,
  .try-again-button,
  .submit-button {
    width: 100%;
  }

  .challenge-content {
    margin: 80px auto 20px;
  }

  .welcome-section,
  .game-section,
  .complete-section,
  .register-section {
    padding: 20px;
  }
}

/* Add these styles */
.example-image {
  margin: 40px auto;
  max-width: 400px;
  text-align: center;
}

.example-image img {
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 12px;
}

.example-image p {
  color: #666;
  font-size: 0.9em;
}

/* Add this with other image-related styles */
.challenge-image {
  margin: 30px auto;
  text-align: center;
  position: relative;
  max-width: 600px;
}

.challenge-image img {
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 12px;
}

.challenge-image p {
  color: #666;
  font-size: 0.9em;
  margin-bottom: 20px;
}

.game-section p {
  max-width: 600px;
  margin: 0 auto 20px;
  color: #555;
}

.game-section textarea:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  z-index: 10;
}

.loading-overlay p {
  margin-top: 20px;
  color: #333;
  font-weight: 500;
}

.game-section textarea {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.game-section textarea:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.cta-button:disabled,
.generate-button:disabled,
.try-again-button:disabled,
.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  opacity: 0.7;
}

/* Update these styles for the textarea wrapper and clear icon */
.textarea-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.textarea-wrapper textarea {
  width: 100%;
  min-height: 120px;
  padding: 12px 40px 12px 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1em;
  resize: vertical;
}

.clear-icon {
  position: absolute;
  top: 12px;
  right: 12px;
  color: #666;
  cursor: pointer;
  padding: 8px;
  font-size: 16px;
  transition: all 0.2s ease;
  z-index: 2;
  background: transparent;
  border-radius: 50%;
}

.clear-icon:hover {
  color: #333;
  background-color: rgba(0, 0, 0, 0.05);
}

/* Add these styles */
.consent-wrapper {
  margin-bottom: 20px;
}

.consent-label {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  cursor: pointer;
  padding: 8px 0;
}

.consent-label input[type="checkbox"] {
  margin-top: 3px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  accent-color: #6200ea;
}

.consent-text {
  font-size: 1em;
  color: #555;
  line-height: 1.4;
  flex: 1;
}

.consent-text a {
  color: #6200ea;
  text-decoration: none;
}

.consent-text a:hover {
  text-decoration: underline;
}
