.audio-guide-page {
    font-family: var(--font-family-base);
    background-color: var(--color-background);
    color: var(--color-text);
    min-width: 320px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 60px;
}

.audio-guide-content {
    width: 100%;
    max-width: none;
    margin: 0;
    padding: 20px;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
}

.audio-guide-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    background-color: white;
    border-radius: var(--border-radius);
    padding: 16px 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.title-section {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.title-section h1 {
    margin: 0;
    font-size: 2rem;
    color: #6200EA;
}

.title-section h1:first-child {
    font-size: 2.5rem;
}

.title-separator {
    display: none;
}

.audio-player {
    background: white;
    border-radius: var(--border-radius);
    padding: 16px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    margin-bottom: 2rem;
}

.current-track {
    text-align: center;
    margin-bottom: 1.5rem;
}

.current-track h2 {
    font-size: 1.25rem;
    color: var(--color-text);
    margin: 0 0 12px 0;
}

.controls {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-bottom: 1.5rem;
}

.controls button {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #6200EA;
    border: none;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
}

.controls button:hover:not(:disabled) {
    transform: scale(1.05);
    background: #5000c9;
}

.controls button:disabled {
    background: rgba(98, 0, 234, 0.3);
    cursor: not-allowed;
}

.controls button i {
    font-size: 1.2rem;
}

.speed-control {
    background: transparent;
    box-shadow: none;
    padding: 0 16px;
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
}

.preset-speeds {
    display: flex;
    justify-content: center;
    gap: 6px;
    margin-bottom: 8px;
}

.preset-speed {
    padding: 4px 8px;
    border: none;
    background: transparent;
    color: #666;
    border-radius: 6px;
    cursor: pointer;
    font-size: 0.85rem;
    transition: all 0.2s ease;
}

.preset-speed:hover {
    background: rgba(98, 0, 234, 0.05);
}

.preset-speed.active {
    background: #6200EA;
    color: white;
    font-weight: 500;
}

.current-speed {
    text-align: center;
    color: #6200EA;
    font-size: 0.9rem;
    font-weight: 500;
    margin: 6px 0;
}

.slider-container {
    position: relative;
    height: 32px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    background: white;
    border-radius: 20px;
    border: 1px solid rgba(98, 0, 234, 0.1);
    margin: 0 4px;
}

/* Base track line */
.speed-slider {
    -webkit-appearance: none;
    width: 100%;
    height: 3px;
    border-radius: 1.5px;
    background: #E0E0E0;
    outline: none;
    position: relative;
    z-index: 1;
}

/* Purple line - fixed width */
.slider-fill {
    position: absolute;
    left: 20px;
    right: 20px;
    height: 3px;
    background: #6200EA;
    border-radius: 1.5px;
    pointer-events: none;
    z-index: 2;
}

/* Thumb styles */
.speed-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #6200EA;
    cursor: pointer;
    position: relative;
    z-index: 3;
    box-shadow: 0 2px 4px rgba(98, 0, 234, 0.2);
    transition: all 0.2s ease;
    border: 2px solid white;
}

.speed-slider::-moz-range-thumb {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #6200EA;
    cursor: pointer;
    position: relative;
    z-index: 3;
    box-shadow: 0 2px 4px rgba(98, 0, 234, 0.2);
    transition: all 0.2s ease;
    border: 2px solid white;
}

/* Track styles for Firefox */
.speed-slider::-moz-range-track {
    height: 4px;
    border-radius: 2px;
    background: #6200EA;
}

.speed-slider::-webkit-slider-thumb:hover,
.speed-slider::-moz-range-thumb:hover {
    transform: scale(1.1);
    box-shadow: 0 2px 8px rgba(98, 0, 234, 0.3);
}

.track-list {
    background: white;
    border-radius: var(--border-radius);
    padding: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    max-height: 60vh;
    overflow-y: auto;
}

.track-item {
    padding: 12px 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    gap: 12px;
}

.track-item:last-child {
    border-bottom: none;
}

.track-item:hover {
    background: rgba(98, 0, 234, 0.05);
}

.track-item.active {
    background: rgba(98, 0, 234, 0.1);
}

.track-item.playing {
    background: rgba(98, 0, 234, 0.05);
    border-left: 3px solid #6200EA;
    padding-left: 13px;
}

.track-item.playing .track-title,
.track-item.playing .track-number {
    font-weight: 600;
    color: #6200EA;
}

.track-item.completed {
    background: rgba(98, 0, 234, 0.05);
}

.track-number {
    color: #666;
    font-size: 0.9rem;
    min-width: 24px;
}

.track-item.completed .track-number,
.track-item.completed .track-title {
    color: var(--color-success-dark);
}

.completed-indicator {
    color: var(--color-success);
    margin-left: auto;
    font-size: 1rem;
}

.track-title {
    flex: 1;
    font-size: 0.95rem;
}

.playing-indicator {
    margin-left: 1rem;
    display: flex;
    align-items: center;
}

.playing-animation {
    display: flex;
    align-items: flex-end;
    gap: 2px;
    height: 16px;
}

.playing-animation span {
    display: inline-block;
    width: 3px;
    background: var(--color-primary);
    border-radius: 1px;
    animation: soundBars 1.2s ease-in-out infinite;
}

.playing-animation span:nth-child(1) {
    height: 8px;
    animation-delay: 0.2s;
}

.playing-animation span:nth-child(2) {
    height: 16px;
    animation-delay: 0.6s;
}

.playing-animation span:nth-child(3) {
    height: 12px;
    animation-delay: 0.4s;
}

@keyframes soundBars {
    0%, 100% { transform: scaleY(1); }
    50% { transform: scaleY(0.5); }
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
    gap: 1rem;
}

/* Mobile-first responsive design */
@media (max-width: 768px) {
    .audio-guide-page {
        min-width: auto;
    }

    .audio-guide-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        padding: 15px;
    }

    .title-section {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }

    .title-section h1 {
        font-size: 1.5rem;
    }

    .controls {
        gap: 0.5rem;
    }

    .controls button {
        width: 40px;
        height: 40px;
    }

    .track-title {
        font-size: 0.9rem;
    }

    .speed-control {
        width: 100%;
        max-width: 320px;
        padding: 16px;
    }

    .preset-speed {
        font-size: 0.85rem;
        padding: 6px 2px;
    }

    .current-speed {
        font-size: 0.9rem;
        margin: 6px 0;
    }

    .slider-container {
        height: 32px;
    }
}

/* Custom scrollbar */
.track-list::-webkit-scrollbar {
    width: 8px;
}

.track-list::-webkit-scrollbar-track {
    background: var(--color-background);
    border-radius: 4px;
}

.track-list::-webkit-scrollbar-thumb {
    background: var(--color-primary);
    border-radius: 4px;
}

.track-list::-webkit-scrollbar-thumb:hover {
    background: var(--color-primary-dark);
}

/* Progress bar styles */
.audio-guide-progress {
    padding: 0 16px;
    margin-bottom: 16px;
}

.audio-guide-progress-bar {
    height: 4px;
    background: rgba(98, 0, 234, 0.1);
    border-radius: 2px;
    position: relative;
    margin-bottom: 6px;
    cursor: pointer;
    overflow: hidden;
    touch-action: none;
}

.audio-guide-progress-bar:hover .audio-guide-progress-handle {
    transform: scale(1);
    opacity: 1;
}

.audio-guide-progress-fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #6200EA;
    border-radius: 2px;
    transform: translateX(-100%);
    will-change: transform;
    transition: transform 60ms linear;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}

.audio-guide-progress-handle {
    position: absolute;
    top: 50%;
    width: 12px;
    height: 12px;
    background: #6200EA;
    border: 2px solid white;
    border-radius: 50%;
    margin-left: -6px;
    margin-top: -6px;
    transition: transform 0.2s ease, opacity 0.2s ease, left 60ms linear;
    transform: scale(0);
    opacity: 0;
    box-shadow: 0 1px 4px rgba(98, 0, 234, 0.2);
    will-change: left, transform;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}

.audio-guide-progress-bar:hover,
.audio-guide-progress-bar:active {
    height: 6px;
}

.audio-guide-time {
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    color: #666;
    padding: 0 2px;
}

/* Track progress in playlist */
.track-item.playing .track-progress {
    height: 2px;
    background: rgba(98, 0, 234, 0.1);
    border-radius: 1px;
    margin-top: 4px;
    overflow: hidden;
}

.track-item.playing .track-progress-fill {
    height: 100%;
    width: 100%;
    background: #6200EA;
    border-radius: 1px;
    transform: translateX(-100%);
    will-change: transform;
    transition: transform 60ms linear;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}

@media (min-width: 768px) {
    .audio-guide-progress {
        padding: 0 24px;
        margin-bottom: 20px;
    }

    .audio-guide-progress-bar {
        height: 5px;
        margin-bottom: 8px;
    }

    .audio-guide-progress-bar:hover,
    .audio-guide-progress-bar:active {
        height: 8px;
    }

    .audio-guide-progress-handle {
        width: 14px;
        height: 14px;
        margin-left: -7px;
        margin-top: -7px;
    }

    .audio-guide-time {
        font-size: 0.8rem;
    }
}

/* Touch device optimizations */
@media (hover: none) {
    .audio-guide-progress-handle {
        transform: scale(1);
        opacity: 1;
    }

    .audio-guide-progress-bar {
        height: 6px;
    }
} 