/* Styles for the SignIn page */

.signin-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #fafafa;
}

.signin-container {
  background-color: #fff;
  border-radius: 16px;
  padding: 40px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
  overflow: hidden;
  max-height: 500px; /* Adjust this value based on your non-registering form height */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align children horizontally */
}

.signin-container.registering {
  max-height: 700px; /* Adjust this value based on your registering form height */
}

.signin-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
}

.signin-header h2 {
  font-size: 1.5rem;
  color: #333;
  margin: 0 0 16px 0;
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
  text-align: center;
}

.auth-option {
  cursor: pointer;
  position: relative;
  transition: color 0.3s ease;
  padding: 0 10px;
  display: inline-block;
}

.auth-option::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: #6200ea;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.auth-option:hover::after,
.auth-option.active::after {
  transform: scaleX(1);
}

.auth-option:hover,
.auth-option.active {
  color: #6200ea;
}

.auth-option.active::after {
  box-shadow: 0 0 10px #6200ea;
}

.toggle-switch {
  align-self: flex-end;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #444;
}

.form-group input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1em;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-group input:focus {
  outline: none;
  border-color: #6200ea;
  box-shadow: 0 0 0 2px rgba(98, 0, 234, 0.2);
}

.error-message {
  color: #d32f2f;
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 0.9em;
}

.cta-button {
  width: 100%;
  background-color: #6200ea;
  color: #fff;
  padding: 14px;
  border: none;
  border-radius: 12px;
  font-size: 1.1em;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(98, 0, 234, 0.2);
  position: relative;
  overflow: hidden;
}

.cta-button:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.3), transparent);
  transform: translateX(-100%);
  transition: 0.6s;
}

.cta-button:hover {
  background-color: #5000c9;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(98, 0, 234, 0.3);
}

.cta-button:hover:before {
  transform: translateX(100%);
}

.cta-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(98, 0, 234, 0.2);
}

.or-divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}

.or-divider::before,
.or-divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ddd;
}

.or-divider span {
  padding: 0 10px;
  color: #777;
  font-size: 0.9em;
}

.google-button {
  width: 100%;
  background-color: #fff;
  color: #757575;
  border: 1px solid #ddd;
  padding: 10px 16px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.google-button:hover {
  background-color: #f5f5f5;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.google-logo {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.password-strength-container {
  margin-top: 8px;
}

.signin-password-strength-meter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
}

.signin-strength-bar {
  height: 4px;
  width: 70%;
  background-color: #e0e0e0;
  border-radius: 2px;
  overflow: hidden;
}

.signin-strength-bar-fill {
  height: 100%;
  width: 0;
  transition: width 0.3s ease;
}

.signin-strength-text {
  font-size: 0.8em;
  color: #666;
  margin-left: 10px;
}

.password-rules {
  font-size: 0.8em;
  color: #666;
  margin-bottom: 4px;
}

.password-strength-wrapper {
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  max-height: 0;
}

.registering .password-strength-wrapper {
  max-height: 100px; /* Adjust this value based on the height of your password strength meter and rules */
}

.fade-enter {
  opacity: 0;
  transform: translateY(0);
  max-height: 0;
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  max-height: 100px; /* Adjust this value based on the content height */
  transition: opacity 300ms, transform 300ms, max-height 300ms;
}

.fade-exit {
  opacity: 1;
  transform: translateY(0);
  max-height: 100px; /* Adjust this value based on the content height */
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(0);
  max-height: 0;
  transition: opacity 300ms, transform 300ms, max-height 300ms;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  max-width: 400px;
  width: 90%;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

.signin-container {
  max-height: none;
  overflow: visible;
}

/* Scoped styling for Sign In and Register buttons */
.signin-container .cta-button {
  /* Override or add specific styles as needed */
  width: 100%;
  max-width: 300px; /* Adjust as necessary */
  margin: 10px 0; /* Add vertical spacing */
}

.signin-cta-button {
  width: 100%;
  max-width: 300px; /* Adjust as necessary */
  margin: 10px 0; /* Add vertical spacing */
  /* Add any additional specific styles here */
}
