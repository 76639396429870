.trip-assistant-chat {
    background: white;
    border-radius: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
    transition: all 0.3s ease;
    overflow: hidden;
    border: 1px solid #e0e0e0;
  }
  
  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background: #6200ea;
    color: white;
    cursor: pointer;
  }
  
  .chat-title {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 600;
  }
  
  .chat-title i {
    font-size: 1.2em;
  }
  
  .toggle-chat-btn {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    padding: 5px;
    transition: transform 0.3s ease;
  }
  
  .trip-assistant-chat:not(.expanded) .toggle-chat-btn {
    transform: rotate(180deg);
  }
  
  .chat-content {
    height: auto;
  }
  
  .chat-messages {
    padding: 20px;
    min-height: 100px;
    max-height: 150px;
    overflow-y: auto;
  }
  
  .empty-chat-message {
    text-align: center;
    color: #666;
  }
  
  .suggestion-chips {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin-top: 15px;
  }
  
  .suggestion-chips button {
    background: #f0e6ff;
    color: #6200ea;
    border: none;
    padding: 8px 16px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 0.9em;
    transition: all 0.2s ease;
  }
  
  .suggestion-chips button:hover {
    background: #6200ea;
    color: white;
  }
  
  .chat-input-container {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px 20px;
    border-top: 1px solid #e0e0e0;
  }
  
  .chat-input {
    flex: 1;
    border: 1px solid #e0e0e0;
    border-radius: 20px;
    padding: 10px 15px;
    resize: none;
    font-family: inherit;
    font-size: 0.9em;
    line-height: 1.5;
    max-height: 100px;
    transition: all 0.2s ease;
  }
  
  .chat-input:focus {
    outline: none;
    border-color: #6200ea;
    box-shadow: 0 0 0 2px rgba(98, 0, 234, 0.1);
  }
  
  .send-message-btn {
    background: #6200ea;
    color: white;
    border: none;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .send-message-btn:hover {
    background: #5000c1;
    transform: scale(1.05);
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .trip-assistant-chat {
      width: 100%;
      margin: 15px 0;
      border-radius: 12px;
    }
  
    .chat-messages {
      min-height: 100px;
      max-height: 150px;
    }
  
    .suggestion-chips button {
      padding: 6px 12px;
      font-size: 0.8em;
    }
  }