/* Color variables */
:root {
    --primary-purple: #6200EA;
    --primary-purple-light: rgba(98, 0, 234, 0.1);
    --primary-purple-hover: rgba(98, 0, 234, 0.2);
}

.audio-guide-page {
    font-family: var(--font-family-base);
    background-color: var(--color-background);
    color: var(--color-text);
    min-width: 320px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 60px;
}

.audio-guide-content {
    width: 100%;
    max-width: none;
    margin: 0;
    padding: 20px;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
}

.audio-guide-header {
    padding: 2rem 0;
    margin-bottom: 2rem;
}

.title-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 0.5rem;
}

.title-section h1 {
    font-size: 2.5rem;
    color: #6200EA;
    margin: 0;
}

.title-section h1:last-of-type {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.title-separator {
    display: none;
}

.audio-player {
    background: white;
    border-radius: var(--border-radius);
    padding: 16px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    margin-bottom: 2rem;
}

.current-track {
    text-align: center;
    margin-bottom: 1.5rem;
}

.current-track h2 {
    font-size: 1.25rem;
    color: var(--color-text);
    margin: 0 0 12px 0;
}

.controls {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin: 1rem 0;
}

.controls button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #6200EA;
    background: transparent;
    color: #6200EA;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.controls button:hover:not(:disabled) {
    background: rgba(98, 0, 234, 0.1);
    transform: scale(1.1);
}

.controls button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.controls button.playing {
    background: #6200EA;
    color: white;
}

.controls button i {
    font-size: 1.2rem;
}

.speed-control {
    background: transparent;
    box-shadow: none;
    padding: 0 16px;
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
    margin-top: 1rem;
}

.preset-speeds {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-bottom: 8px;
}

.preset-speed {
    padding: 4px 8px;
    border: 1px solid #6200EA;
    border-radius: 4px;
    background: transparent;
    color: #6200EA;
    cursor: pointer;
    transition: all 0.2s ease;
}

.preset-speed:hover {
    background: rgba(98, 0, 234, 0.1);
}

.preset-speed.active {
    background: #6200EA;
    color: white;
}

.current-speed {
    text-align: center;
    color: #6200EA;
    font-size: 0.9rem;
    font-weight: 500;
    margin: 6px 0;
}

.slider-container {
    position: relative;
    height: 32px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    background: white;
    border-radius: 20px;
    border: 1px solid rgba(98, 0, 234, 0.1);
    margin: 0 4px;
}

/* Base track line */
.speed-slider {
    -webkit-appearance: none;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background: rgba(98, 0, 234, 0.1);
    outline: none;
    margin: 1rem 0;
}

/* Purple line - fixed width */
.slider-fill {
    position: absolute;
    left: 20px;
    right: 20px;
    height: 3px;
    background: #6200EA;
    border-radius: 1.5px;
    pointer-events: none;
    z-index: 2;
}

/* Thumb styles */
.speed-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #6200EA;
    border: 2px solid white;
    cursor: pointer;
    box-shadow: 0 1px 4px rgba(98, 0, 234, 0.2);
}

.speed-slider::-moz-range-thumb {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #6200EA;
    border: 2px solid white;
    cursor: pointer;
    box-shadow: 0 1px 4px rgba(98, 0, 234, 0.2);
}

/* Track styles for Firefox */
.speed-slider::-moz-range-track {
    height: 4px;
    border-radius: 2px;
    background: #6200EA;
}

.speed-slider::-webkit-slider-thumb:hover,
.speed-slider::-moz-range-thumb:hover {
    transform: scale(1.1);
    box-shadow: 0 2px 8px rgba(98, 0, 234, 0.3);
}

.track-list {
    background: white;
    border-radius: var(--border-radius);
    padding: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    max-height: 60vh;
    overflow-y: auto;
}

.track-item {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    background: white;
    margin-bottom: 8px;
    position: relative;
}

.track-info {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 12px;
    min-width: 0; /* For text truncation */
}

.track-number {
    color: #6200EA;
    font-weight: 600;
    min-width: 28px;
}

.track-title {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.track-duration {
    font-family: monospace;
    color: #666;
    margin-left: 16px;
    margin-right: 8px;
    font-size: 0.9em;
    min-width: 45px;
    text-align: right;
}

.track-item:hover {
    background: rgba(98, 0, 234, 0.05);
}

.track-item.active {
    background: rgba(98, 0, 234, 0.1);
}

.track-item.playing .track-duration {
    color: #6200EA;
    font-weight: 600;
}

.playing-indicator {
    margin-left: 12px;
    display: flex;
    align-items: center;
}

.playing-animation {
    display: flex;
    align-items: flex-end;
    gap: 2px;
    height: 12px;
}

.playing-animation span {
    width: 2px;
    background: #6200EA;
    animation: sound 0.8s infinite alternate;
}

.playing-animation span:nth-child(1) {
    height: 8px;
    animation-delay: 0.1s;
}

.playing-animation span:nth-child(2) {
    height: 12px;
    animation-delay: 0.2s;
}

.playing-animation span:nth-child(3) {
    height: 6px;
    animation-delay: 0.3s;
}

@keyframes sound {
    0% {
        transform: scaleY(0.5);
    }
    100% {
        transform: scaleY(1);
    }
}

.completed-indicator {
    color: #6200EA;
    margin-left: 12px;
}

/* Mobile optimizations */
@media (max-width: 768px) {
    .track-item {
        padding: 10px 12px;
    }

    .track-info {
        gap: 8px;
    }

    .track-number {
        min-width: 24px;
        font-size: 0.9em;
    }

    .track-title {
        font-size: 0.9em;
    }

    .track-duration {
        font-size: 0.8em;
        min-width: 40px;
        margin-left: 12px;
        margin-right: 6px;
    }
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
    gap: 1rem;
}

/* Mobile-first responsive design */
@media (max-width: 768px) {
    .audio-guide-page {
        min-width: auto;
    }

    .audio-guide-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        padding: 15px;
    }

    .title-section {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }

    .title-section h1 {
        font-size: 1.5rem;
    }

    .controls {
        gap: 0.5rem;
    }

    .controls button {
        width: 40px;
        height: 40px;
    }

    .track-title {
        font-size: 0.9rem;
    }

    .speed-control {
        width: 100%;
        max-width: 320px;
        padding: 16px;
    }

    .preset-speed {
        font-size: 0.85rem;
        padding: 6px 2px;
    }

    .current-speed {
        font-size: 0.9rem;
        margin: 6px 0;
    }

    .slider-container {
        height: 32px;
    }
}

/* Custom scrollbar */
.track-list::-webkit-scrollbar {
    width: 8px;
}

.track-list::-webkit-scrollbar-track {
    background: var(--color-background);
    border-radius: 4px;
}

.track-list::-webkit-scrollbar-thumb {
    background: var(--color-primary);
    border-radius: 4px;
}

.track-list::-webkit-scrollbar-thumb:hover {
    background: var(--color-primary-dark);
}

/* Progress bar styles */
.audio-guide-progress {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    margin: 1rem 0;
}

.time-display {
    min-width: 60px;
    text-align: center;
    font-family: monospace;
    font-size: 1rem;
    color: #333;
}

.audio-guide-progress-bar {
    flex: 1;
    height: 6px;
    background: rgba(98, 0, 234, 0.1);
    border-radius: 3px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
}

.audio-guide-progress-fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #6200EA;
    transform-origin: left;
}

.audio-guide-progress-handle {
    position: absolute;
    top: 50%;
    width: 12px;
    height: 12px;
    background: #6200EA;
    border: 2px solid white;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    cursor: grab;
    box-shadow: 0 1px 4px rgba(98, 0, 234, 0.2);
}

.audio-guide-progress-handle:hover {
    transform: translate(-50%, -50%) scale(1.2);
}

.audio-guide-progress-bar:hover .audio-guide-progress-handle {
    transform: translate(-50%, -50%) scale(1.2);
}

/* Mobile optimizations */
@media (max-width: 768px) {
    .audio-guide-progress {
        gap: 8px;
    }

    .time-display {
        min-width: 50px;
        font-size: 0.9rem;
    }

    .audio-guide-progress-bar {
        height: 4px;
    }

    .audio-guide-progress-handle {
        width: 10px;
        height: 10px;
    }
}

.language-toggle {
    display: flex;
    gap: 8px;
    margin-left: 20px;
    align-items: center;
}

.lang-btn {
    padding: 6px 12px;
    border: 2px solid #333;
    background: transparent;
    color: #333;
    font-weight: 600;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.lang-btn:hover {
    background: #f0f0f0;
}

.lang-btn.active {
    background: #333;
    color: white;
}

/* Image Slider */
.image-slider {
    position: relative;
    width: 100%;
    max-width: 1350px;
    margin: 0 auto 2rem;
    border-radius: 12px;
    overflow: hidden;
    background: #f5f5f5;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.image-slider.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    max-width: none;
    margin: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.9);
    border-radius: 0;
}

.image-container {
    position: relative;
    width: 100%;
    padding-top: 66.67%; /* Maintain 3:2 aspect ratio */
    cursor: pointer;
}

.image-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: transform 0.3s ease;
}

.fullscreen .image-container {
    padding-top: 100vh;
}

.nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.9);
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
    font-size: 1.2rem;
    transition: all 0.2s ease;
    z-index: 2;
}

.nav-button:hover {
    background: white;
    transform: translateY(-50%) scale(1.1);
}

.nav-button.prev {
    left: 16px;
}

.nav-button.next {
    right: 16px;
}

.fullscreen .nav-button {
    width: 48px;
    height: 48px;
    font-size: 1.4rem;
    background: rgba(255, 255, 255, 0.15);
    color: white;
}

.fullscreen .nav-button:hover {
    background: rgba(255, 255, 255, 0.25);
}

.close-fullscreen {
    position: absolute;
    top: 20px;
    right: 20px;
    background: rgba(255, 255, 255, 0.15);
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1.2rem;
    transition: all 0.2s ease;
    z-index: 2;
}

.close-fullscreen:hover {
    background: rgba(255, 255, 255, 0.25);
    transform: scale(1.1);
}

.image-dots {
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 8px;
    z-index: 2;
}

.dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
    border: none;
    padding: 0;
    cursor: pointer;
    transition: all 0.2s ease;
}

.dot.active {
    background: white;
    transform: scale(1.2);
}

.fullscreen .dot {
    width: 10px;
    height: 10px;
}

/* Mobile optimizations */
@media (max-width: 768px) {
    .image-slider {
        border-radius: 0;
        margin-bottom: 1rem;
    }

    .nav-button {
        width: 36px;
        height: 36px;
        font-size: 1rem;
    }

    .image-dots {
        bottom: 12px;
    }

    .dot {
        width: 6px;
        height: 6px;
    }
}

/* Touch device optimizations */
@media (hover: none) {
    .nav-button {
        background: rgba(255, 255, 255, 0.9);
    }

    .fullscreen .nav-button {
        background: rgba(255, 255, 255, 0.2);
    }
}

.track-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.track-header h2 {
    margin: 0;
    flex: 1;
}

.autoplay-toggle {
    margin-left: 1rem;
}

.autoplay-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    border: 2px solid #333;
    background: transparent;
    color: #333;
    font-weight: 600;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.autoplay-btn:hover {
    background: #f0f0f0;
}

.autoplay-btn.active {
    background: #333;
    color: white;
}

.autoplay-btn i {
    font-size: 0.9em;
}

.autoplay-btn span {
    font-size: 0.9em;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

/* Mobile optimization */
@media (max-width: 768px) {
    .track-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }

    .autoplay-toggle {
        margin-left: 0;
        align-self: flex-end;
    }

    .autoplay-btn {
        padding: 6px 10px;
        font-size: 0.9em;
    }
}

.controls-group {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 1rem;
}

.controls-separator {
    color: #333;
    font-weight: 300;
    opacity: 0.5;
}

.language-toggle {
    display: flex;
    gap: 8px;
}

.autoplay-toggle {
    display: flex;
    align-items: center;
}

.lang-btn, .autoplay-btn {
    padding: 6px 12px;
    border: 2px solid #333;
    background: transparent;
    color: #333;
    font-weight: 600;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.lang-btn:hover, .autoplay-btn:hover {
    background: #f0f0f0;
}

.lang-btn.active, .autoplay-btn.active {
    background: #333;
    color: white;
}

.autoplay-btn {
    display: flex;
    align-items: center;
    gap: 6px;
}

.autoplay-btn i {
    font-size: 0.9em;
}

.autoplay-btn span {
    font-size: 0.9em;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

/* Mobile optimization */
@media (max-width: 768px) {
    .title-section {
        flex-direction: column;
        align-items: flex-start;
    }

    .controls-group {
        margin-left: 0;
        margin-top: 12px;
        width: 100%;
        justify-content: flex-end;
    }

    .lang-btn, .autoplay-btn {
        padding: 4px 8px;
        font-size: 0.9em;
    }
}

.direction-button {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: none;
    background: #6200EA;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-left: 12px;
    padding: 0;
}

.direction-button:hover {
    transform: scale(1.1);
    background: #5000c9;
}

.direction-button i {
    font-size: 16px;
}

/* Adjust spacing when both direction and playing/completed indicators are present */
.direction-button + .playing-indicator,
.direction-button + .completed-indicator {
    margin-left: 8px;
}

/* Mobile optimizations */
@media (max-width: 768px) {
    .direction-button {
        width: 28px;
        height: 28px;
        margin-left: 8px;
    }

    .direction-button i {
        font-size: 14px;
    }
} 