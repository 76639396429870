.trivia-page {
  min-height: 100vh;
  background-color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-family: 'Inter', sans-serif;
  color: #333;
}

.trivia-page .trivia-container {
  background-color: #fff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  max-width: 850px;
  width: 100%;
  padding: 50px;
  animation: fadeIn 0.6s ease-out;
}

.trivia-page .progress-bar {
  width: 100%;
  height: 8px;
  background-color: #ddd;
  border-radius: 4px;
  margin-bottom: 30px;
  overflow: hidden;
}

.trivia-page .progress-bar-fill {
  height: 100%;
  background: #6200ea;
  transition: width 0.3s ease;
}

.trivia-page .trivia-question h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 2em;
  text-align: center;
  margin-bottom: 30px;
  color: #222;
  font-weight: 700;
}

.trivia-page .options-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.trivia-page .option-card {
  background: #fff;
  border: 2px solid #ddd;
  border-radius: 16px;
  width: 200px;
  padding: 30px 20px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  isolation: isolate;
}

.trivia-page .option-card::after {
  content: '';
  background: #6200ea;
  position: absolute;
  top: -50%;
  left: -50%;
  width: 0;
  height: 200%;
  transform: rotate(45deg);
  transition: all 0.5s ease;
  z-index: 0;
}

.trivia-page .option-card:hover::after {
  width: 250%;
}

.trivia-page .option-card.selected::after {
  width: 250%;
}

.trivia-page .option-card:hover,
.trivia-page .option-card.selected {
  color: #fff;
}

.trivia-page .option-card .option-icon {
  font-size: 40px;
  margin-bottom: 15px;
  color: #6200ea;
  transition: color 0.4s ease;
  position: relative;
  z-index: 1;
}

.trivia-page .option-card:hover .option-icon,
.trivia-page .option-card.selected .option-icon {
  color: #ffffff;
}

.trivia-page .option-card span {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
  transition: color 0.4s ease;
  position: relative;
  z-index: 1;
}

.trivia-page .option-card p {
  font-size: 0.9em;
  color: #666;
  position: relative;
  z-index: 1;
  transition: color 0.4s ease;
}

.trivia-page .option-card:hover p,
.trivia-page .option-card.selected p {
  color: #fff;
}

.trivia-page .navigation-buttons {
  width: 100%;
  text-align: center;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.trivia-page .cta-button {
  background-color: #6200ea;
  color: #fff;
  border: none;
  border-radius: 12px;
  padding: 14px 28px;
  font-size: 1.1em;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  box-shadow: 0 4px 6px rgba(98, 0, 234, 0.2);
}

.trivia-page .cta-button:hover {
  background-color: #5000c9;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(98, 0, 234, 0.3);
}

.trivia-page .cta-button:disabled {
  background-color: #ddd;
  color: #999;
  transform: none;
  box-shadow: none;
}

.trivia-page .trivia-welcome h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 2.8em;
  margin-bottom: 20px;
  color: #222;
  text-align: center;
  font-weight: 700;
}

.trivia-page .trivia-welcome p {
  font-size: 1.2em;
  margin-bottom: 40px;
  color: #555;
  text-align: center;
}

.trivia-page .trivia-thanks {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.trivia-page .success-icon {
  font-size: 80px;
  color: #4CAF50;
  margin-bottom: 20px;
  animation: popIn 0.5s ease;
  display: block;
  text-align: center;
}

.trivia-page .trivia-thanks h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.trivia-page .trivia-thanks p {
  font-size: 1.2em;
  margin-bottom: 40px;
  color: #555;
  text-align: center;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px);}
  to { opacity: 1; transform: translateY(0);}
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% { transform: translateY(0);}
  40% { transform: translateY(-20px);}
  60% { transform: translateY(-10px);}
}

@keyframes popIn {
  from { transform: scale(0); }
  to { transform: scale(1); }
}

@media (max-width: 768px) {
  .trivia-page .trivia-container {
    padding: 30px;
  }

  .trivia-page .option-card {
    width: 100%;
  }

  .trivia-page .options-grid {
    flex-direction: column;
    align-items: center;
  }
}

.trivia-page .basics-form {
  max-width: 600px;
  margin: 0 auto;
}

.trivia-page .form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.trivia-page .form-group {
  flex: 1;
}

.trivia-page .form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #333;
}

.trivia-page .form-group input {
  width: 100%;
  max-width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1em;
  background-color: #fff;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 48px;
}

.trivia-page .form-group input:focus {
  outline: none;
  border-color: #6200ea;
  box-shadow: 0 0 0 4px rgba(98, 0, 234, 0.2);
}

.trivia-page .radio-group {
  display: flex;
  gap: 10px;
}

.trivia-page .radio-button {
  padding: 10px 20px;
  border: 2px solid #ddd;
  border-radius: 8px;
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1em;
  min-width: 100px;
  text-align: center;
}

.trivia-page .radio-button.selected {
  background: linear-gradient(45deg, #6200ea, #5000c9);
  border-color: transparent;
  color: white;
}

.trivia-page .radio-button:hover:not(.selected) {
  border-color: #6200ea;
}

@media (max-width: 768px) {
  .trivia-page .form-row {
    flex-direction: column;
    gap: 10px;
  }
}

.trivia-page .cta-button.secondary {
  background-color: #fff;
  color: #6200ea;
  border: 2px solid #6200ea;
}

.trivia-page .cta-button.secondary:hover {
  background-color: #f5f0ff;
}

.trivia-page .options-grid .option-card {
  width: 180px;
}

@media (max-width: 768px) {
  .trivia-page .options-grid .option-card {
    width: 100%;
  }
}

.trivia-page .subtitle {
  text-align: center;
  color: #666;
  margin-bottom: 30px;
  font-size: 1.1em;
}

.trivia-page .error-message {
  color: red;
  font-size: 0.9em;
  margin-top: 5px;
  text-align: center;
}