@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.flappy-bird-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1e1e1e;
  position: relative;
  width: 100%;
  height: 100vh;
  font-family: 'Press Start 2P', cursive;
}

canvas {
  border: 4px solid #6200ea;
  background-color: #2c2c2c;
  image-rendering: pixelated;
  box-sizing: border-box;
  width: 480px;
  height: 640px;
}

@media (max-width: 768px) {
  canvas {
    width: 90%;
    height: auto;
  }
}

.score-display {
  position: absolute;
  top: 20px;
  font-size: 16px;
  color: #6200ea;
  text-shadow: 2px 2px #000000;
}

.game-over-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 480px;
  height: 640px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
