.audio-features-page {
    font-family: var(--font-family-base);
    background-color: #fafafa;
    color: var(--color-text);
    min-width: 320px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 60px;
}

.main-content {
    flex: 1;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
}

.content-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto 1fr;
    gap: 40px;
    align-items: stretch;
}

.content-block {
    background: white;
    padding: 40px;
    border-radius: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
}

/* Hero Section */
.hero-section {
    grid-column: 1;
    grid-row: 1;
    text-align: left;
}

.hero-section h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 2.5em;
    margin: 0 0 20px;
    line-height: 1.2;
}

.hero-section .highlight {
    color: #6200EA;
    display: block;
}

.hero-section h2 {
    font-size: 1.5em;
    color: #333;
    margin: 0 0 20px;
}

.hero-description {
    font-size: 1.1em;
    line-height: 1.6;
    color: #555;
    margin: 0;
}

/* Features Section */
.features-section {
    grid-column: 2;
    grid-row: 1;
}

.features-section h2 {
    font-size: 2em;
    color: #333;
    margin: 0 0 30px;
}

.features-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.feature-item {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.feature-icon {
    font-size: 1.5em;
    color: #6200EA;
}

.feature-item h3 {
    font-size: 1.3em;
    color: #333;
    margin: 0;
}

.feature-item p {
    font-size: 1em;
    color: #666;
    line-height: 1.6;
    margin: 0;
}

/* Solutions Section */
.solutions-section {
    grid-column: 1;
    grid-row: 2;
}

.solutions-section h2 {
    font-size: 2em;
    color: #333;
    margin: 0 0 30px;
}

.solutions-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.solution-item {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.solution-item h3 {
    font-size: 1.3em;
    margin: 0;
}

.solution-item h3.highlight {
    color: #6200EA;
}

.solution-item p {
    font-size: 1em;
    color: #666;
    line-height: 1.6;
    margin: 0;
}

/* CTA Section */
.cta-section {
    grid-column: 2;
    grid-row: 2;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.cta-section h2 {
    font-size: 2em;
    color: #333;
    margin: 0 0 20px;
}

.cta-section p {
    flex: 1;
    font-size: 1.1em;
    line-height: 1.6;
    color: #555;
    margin: 0 0 30px;
}

.cta-buttons {
    display: flex;
    gap: 20px;
}

.cta-button {
    display: inline-block;
    padding: 12px 24px;
    font-size: 1em;
    font-weight: 600;
    text-decoration: none;
    border-radius: 8px;
    transition: all 0.3s ease;
}

.cta-button.primary {
    background-color: #6200EA;
    color: white;
}

.cta-button.secondary {
    background-color: transparent;
    color: #6200EA;
    border: 2px solid #6200EA;
}

.cta-button:hover {
    transform: translateY(-2px);
}

.cta-button.primary:hover {
    background-color: #5000c9;
}

.cta-button.secondary:hover {
    background-color: rgba(98, 0, 234, 0.1);
}

/* Mobile Responsiveness */
@media (max-width: 1024px) {
    .content-grid {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        gap: 30px;
    }

    .hero-section,
    .features-section,
    .solutions-section,
    .cta-section {
        grid-column: 1;
        grid-row: auto;
    }
}

@media (max-width: 768px) {
    .main-content {
        padding: 20px;
    }

    .content-block {
        padding: 30px;
    }

    .hero-section h1 {
        font-size: 2em;
    }

    .hero-section h2,
    .features-section h2,
    .solutions-section h2,
    .cta-section h2 {
        font-size: 1.8em;
    }

    .cta-buttons {
        flex-direction: column;
    }

    .cta-button {
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 480px) {
    .content-block {
        padding: 20px;
    }

    .hero-section h1 {
        font-size: 1.8em;
    }

    .hero-section h2,
    .features-section h2,
    .solutions-section h2,
    .cta-section h2 {
        font-size: 1.5em;
    }
} 