/* Color variables */
:root {
    --primary-purple: #6200EA;
    --primary-purple-light: rgba(98, 0, 234, 0.1);
    --primary-purple-hover: rgba(98, 0, 234, 0.2);
}

.audio-guide-page {
    font-family: var(--font-family-base);
    background-color: var(--color-background);
    color: var(--color-text);
    min-width: 320px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 60px;
}

.audio-guide-content {
    width: 100%;
    max-width: none;
    margin: 0;
    padding: 20px;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
}

.audio-guide-header {
    padding: 2rem 0;
    margin-bottom: 2rem;
}

.title-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 0.5rem;
}

.title-section h1 {
    font-size: 2.5rem;
    color: #6200EA;
    margin: 0;
}

.title-section h1:last-of-type {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.audio-player {
    background: white;
    border-radius: var(--border-radius);
    padding: 16px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    margin-bottom: 2rem;
}

.current-track {
    text-align: center;
    margin-bottom: 1.5rem;
}

.current-track h2 {
    font-size: 1.25rem;
    color: var(--color-text);
    margin: 0 0 12px 0;
}

.controls {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin: 1rem 0;
}

.controls button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #6200EA;
    background: transparent;
    color: #6200EA;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.controls button:hover:not(:disabled) {
    background: rgba(98, 0, 234, 0.1);
    transform: scale(1.1);
}

.controls button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.controls button.playing {
    background: #6200EA;
    color: white;
}

.controls button i {
    font-size: 1.2rem;
}

.speed-control {
    background: transparent;
    box-shadow: none;
    padding: 0 16px;
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
    margin-top: 1rem;
}

.preset-speeds {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-bottom: 8px;
}

.preset-speed {
    padding: 4px 8px;
    border: 1px solid #6200EA;
    border-radius: 4px;
    background: transparent;
    color: #6200EA;
    cursor: pointer;
    transition: all 0.2s ease;
}

.preset-speed:hover {
    background: rgba(98, 0, 234, 0.1);
}

.preset-speed.active {
    background: #6200EA;
    color: white;
}

.track-list {
    background: white;
    border-radius: var(--border-radius);
    padding: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    max-height: 60vh;
    overflow-y: auto;
}

.track-group {
    margin-bottom: 2rem;
}

.track-group:last-child {
    margin-bottom: 0;
}

.track-group-title {
    color: #6200EA;
    font-size: 1.2rem;
    margin: 0 0 1rem 0;
    padding: 0.5rem 1rem;
    background: rgba(98, 0, 234, 0.1);
    border-radius: 4px;
}

.track-item {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    background: white;
    margin-bottom: 8px;
    position: relative;
}

.track-info {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 12px;
    min-width: 0; /* For text truncation */
}

.track-number {
    color: #6200EA;
    font-weight: 600;
    min-width: 28px;
}

.track-title {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.track-duration {
    font-family: monospace;
    color: #666;
    margin-left: 16px;
    margin-right: 8px;
    font-size: 0.9em;
    min-width: 45px;
    text-align: right;
}

.track-item:hover {
    background: rgba(98, 0, 234, 0.05);
}

.track-item.active {
    background: rgba(98, 0, 234, 0.1);
}

.track-item.playing .track-duration {
    color: #6200EA;
    font-weight: 600;
}

.playing-indicator {
    margin-left: 12px;
    display: flex;
    align-items: center;
}

.playing-animation {
    display: flex;
    align-items: flex-end;
    gap: 2px;
    height: 12px;
}

.playing-animation span {
    width: 2px;
    background: #6200EA;
    animation: sound 0.8s infinite alternate;
}

.playing-animation span:nth-child(1) {
    height: 8px;
    animation-delay: 0.1s;
}

.playing-animation span:nth-child(2) {
    height: 12px;
    animation-delay: 0.2s;
}

.playing-animation span:nth-child(3) {
    height: 6px;
    animation-delay: 0.3s;
}

@keyframes sound {
    0% {
        transform: scaleY(0.5);
    }
    100% {
        transform: scaleY(1);
    }
}

.completed-indicator {
    color: #6200EA;
    margin-left: 12px;
}

/* Mobile optimizations */
@media (max-width: 768px) {
    .track-item {
        padding: 10px 12px;
    }

    .track-info {
        gap: 8px;
    }

    .track-number {
        min-width: 24px;
        font-size: 0.9em;
    }

    .track-title {
        font-size: 0.9em;
    }

    .track-duration {
        font-size: 0.8em;
        min-width: 40px;
        margin-left: 12px;
        margin-right: 6px;
    }

    .track-group-title {
        font-size: 1.1rem;
        padding: 0.4rem 0.8rem;
    }
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
    gap: 1rem;
}

/* Progress bar styles */
.audio-guide-progress {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    margin: 1rem 0;
}

.time-display {
    min-width: 60px;
    text-align: center;
    font-family: monospace;
    font-size: 1rem;
    color: #333;
}

.audio-guide-progress-bar {
    flex: 1;
    height: 6px;
    background: rgba(98, 0, 234, 0.1);
    border-radius: 3px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
}

.audio-guide-progress-fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #6200EA;
    transform-origin: left;
}

.audio-guide-progress-handle {
    position: absolute;
    top: 50%;
    width: 12px;
    height: 12px;
    background: #6200EA;
    border: 2px solid white;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    cursor: grab;
    box-shadow: 0 1px 4px rgba(98, 0, 234, 0.2);
}

.audio-guide-progress-handle:hover {
    transform: translate(-50%, -50%) scale(1.2);
}

.audio-guide-progress-bar:hover .audio-guide-progress-handle {
    transform: translate(-50%, -50%) scale(1.2);
}

.controls-group {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 1rem;
}

.controls-separator {
    color: #333;
    font-weight: 300;
    opacity: 0.5;
}

.language-toggle {
    display: flex;
    gap: 8px;
}

.autoplay-toggle {
    display: flex;
    align-items: center;
}

.lang-btn, .autoplay-btn {
    padding: 6px 12px;
    border: 2px solid #333;
    background: transparent;
    color: #333;
    font-weight: 600;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.lang-btn:hover, .autoplay-btn:hover {
    background: #f0f0f0;
}

.lang-btn.active, .autoplay-btn.active {
    background: #333;
    color: white;
}

.autoplay-btn {
    display: flex;
    align-items: center;
    gap: 6px;
}

.autoplay-btn i {
    font-size: 0.9em;
}

.autoplay-btn span {
    font-size: 0.9em;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

/* Mobile optimization */
@media (max-width: 768px) {
    .audio-guide-page {
        min-width: auto;
        width: 100%;
        overflow-x: hidden; /* Prevent horizontal scroll */
    }

    .audio-guide-content {
        padding: 15px;
    }

    .audio-guide-header {
        padding: 1rem 0;
        margin-bottom: 1rem;
        width: 100%;
    }

    .title-section {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }

    .title-section h1 {
        font-size: 1.8rem;
        width: 100%;
        word-wrap: break-word;
        line-height: 1.2;
    }

    .title-section h1:last-of-type {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
    }

    .controls-group {
        width: 100%;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 8px;
        margin-top: 12px;
    }

    .language-toggle {
        margin-left: 0;
    }

    .lang-btn, .autoplay-btn {
        padding: 4px 8px;
        font-size: 0.85em;
        min-width: auto;
    }

    .controls-separator {
        display: none;
    }

    .autoplay-toggle {
        margin-left: 0;
    }

    .autoplay-btn {
        padding: 4px 8px;
    }

    .autoplay-btn span {
        font-size: 0.85em;
    }

    .controls {
        gap: 0.5rem;
    }

    .controls button {
        width: 40px;
        height: 40px;
    }

    .track-title {
        font-size: 0.9rem;
    }

    .audio-guide-progress {
        gap: 8px;
    }

    .time-display {
        min-width: 50px;
        font-size: 0.9rem;
    }

    .audio-guide-progress-bar {
        height: 4px;
    }

    .audio-guide-progress-handle {
        width: 10px;
        height: 10px;
    }
}

/* Custom scrollbar */
.track-list::-webkit-scrollbar {
    width: 8px;
}

.track-list::-webkit-scrollbar-track {
    background: var(--color-background);
    border-radius: 4px;
}

.track-list::-webkit-scrollbar-thumb {
    background: var(--color-primary);
    border-radius: 4px;
}

.track-list::-webkit-scrollbar-thumb:hover {
    background: var(--color-primary-dark);
}

/* Track Image styles */
.track-image {
    width: 100%;
    max-width: 800px;
    margin: 0 auto 2rem;
    border-radius: 12px;
    overflow: hidden;
    background: #f5f5f5;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.track-image img {
    width: 100%;
    height: 300px;
    object-fit: contain;
    display: block;
    background: white;
}

@media (max-width: 768px) {
    .track-image {
        border-radius: 8px;
        margin-bottom: 1rem;
    }

    .track-image img {
        height: 200px;
    }
} 