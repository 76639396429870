.site-footer {
  background-color: #fff;
  padding: 40px 20px;
  font-family: var(--font-family-base);
  color: #333;
  border-top: 1px solid #e0e0e0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 16px; /* Updated to round all corners */
  margin-top: 40px; /* Space between content and footer */
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-left {
  display: flex;
  gap: 40px;
}

.footer-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Ensure left alignment */
}

.footer-column-left {
  align-items: flex-start; /* Ensure left alignment */
}

.footer-link {
  margin-bottom: 10px;
  color: #333;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: #6200ea;
}

.footer-right {
  display: flex;
  gap: 15px;
}

.social-icon {
  color: #333;
  font-size: 1.2em;
  transition: transform 0.3s ease, color 0.3s ease;
}

.social-icon:hover {
  color: #6200ea;
  transform: translateY(-5px);
}

.footer-divider {
  border: none;
  border-top: 1px solid #ddd;
  margin: 30px 0 0 0; /* Remove bottom margin */
}

.footer-bottom {
  text-align: left;
  font-size: 0.9em;
  color: #666;
  margin-top: 8px; /* Reduced space between line and text */
  padding-left: 20px; /* Adds padding from the left edge */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: flex-start; /* Keep left alignment */
  }

  .footer-left {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .footer-column {
    align-items: flex-start; /* Maintain left alignment on mobile */
  }

  .footer-right {
    margin-top: 20px;
    align-self: center; /* Center social icons */
  }

  .footer-bottom {
    text-align: left; /* Maintain left alignment on mobile */
  }
}
