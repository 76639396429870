.advanced-settings {
  background-color: white;
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.advanced-settings h2 {
  font-size: 1.5rem;
  margin-bottom: 24px;
  color: #333;
  text-align: center;
}

.setting-group {
  margin-bottom: 20px;
}

.switch-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px; /* Add some space between switch items */
}

.switch-label span {
  margin-right: 10px;
  white-space: nowrap;
}

.toggle-line {
  flex-grow: 1;
  height: 1px;
  background-color: #ddd;
  margin: 0 10px;
}

.persons-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.persons-group label {
  margin-right: 10px;
}

.person-buttons {
  display: flex;
  gap: 8px;
}

.person-buttons button {
  background-color: #f0f0f0;
  border: none;
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
}

.person-buttons button.active {
  background-color: #6200ea;
  color: white;
}

.budget-label {
  display: block;
  text-align: center;
  margin-bottom: 10px;
}

.budget-input {
  display: flex;
  gap: 8px;
}

.budget-input input,
.budget-input select {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1em;
  transition: all 0.3s ease;
}

.budget-input input:focus,
.budget-input select:focus {
  outline: none;
  border-color: #6200ea;
  box-shadow: 0 0 0 2px rgba(98, 0, 234, 0.2);
}

/* Apply Button Styles */
.apply-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.apply-button {
  padding: 10px 15px;
  background-color: #6200ea;
  color: #fff;
  border: 2px solid #6200ea;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.apply-button:hover {
  background-color: #fff;
  color: #6200ea;
  border-color: #6200ea;
}

.apply-button:active {
  transform: translateY(0);
}
