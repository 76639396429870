.contact-page {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: 'Inter', sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  line-height: 1.6;
  background-color: #fafafa;
  color: #333;
}

.main-content {
  flex: 1;
  margin-top: 60px;
}

.content-block {
  background-color: #fff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 40px;
}

h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 3em;
  margin-bottom: 20px;
  color: #222;
  font-weight: 700;
}

p {
  font-size: 1.15em;
  margin-bottom: 30px;
  color: #555;
}

.contact-info {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
  margin: 40px 0;
}

.contact-card {
  background-color: #f8f9fa;
  padding: 24px;
  border-radius: 12px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.contact-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.contact-card h3 {
  color: #333;
  font-size: 1.2em;
  margin-bottom: 12px;
  font-weight: 600;
}

.contact-card a {
  color: #6200ea;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.1em;
  display: block;
  margin-bottom: 8px;
  transition: color 0.3s ease;
}

.contact-card a:hover {
  color: #5000c9;
}

.contact-card p {
  color: #666;
  font-size: 0.9em;
  margin: 0;
}

.form-divider {
  position: relative;
  text-align: center;
  margin: 40px 0;
}

.form-divider::before,
.form-divider::after {
  content: '';
  position: absolute;
  top: 50%;
  width: calc(50% - 100px);
  height: 1px;
  background-color: #ddd;
}

.form-divider::before {
  left: 0;
}

.form-divider::after {
  right: 0;
}

.form-divider span {
  background-color: #fff;
  padding: 0 20px;
  color: #666;
  font-size: 1.1em;
}

.form-group {
  margin-bottom: 24px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #444;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1em;
  background-color: #fff;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-group input,
.form-group select {
  height: 48px;
}

.form-group textarea {
  min-height: 120px;
  resize: vertical;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #6200ea;
  box-shadow: 0 0 0 4px rgba(98, 0, 234, 0.2);
}

.cta-button {
  background-color: #6200ea;
  color: #fff;
  padding: 14px 28px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 1.1em;
  font-weight: 600;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 100%;
  min-width: 200px;
  box-shadow: 0 4px 6px rgba(98, 0, 234, 0.2);
  position: relative;
  overflow: hidden;
}

.cta-button:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.3), transparent);
  transform: translateX(-100%);
  transition: 0.6s;
}

.cta-button:hover {
  background-color: #5000c9;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(98, 0, 234, 0.3);
}

.cta-button:hover:before {
  transform: translateX(100%);
}

.cta-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(98, 0, 234, 0.2);
}

.success-message-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  text-align: center;
}

.success-content {
  max-width: 500px;
  margin: 0 auto;
  padding: 40px 20px;
}

.success-icon {
  font-size: 64px;
  color: #4CAF50;
  margin-bottom: 24px;
  display: block;
}

.success-content h1 {
  margin-bottom: 16px;
}

.success-content p {
  margin-bottom: 32px;
  font-size: 1.2em;
  color: #666;
}

.success-content .cta-button {
  max-width: 200px;
  margin: 0 auto;
}

/* Media Queries */
@media (max-width: 768px) {
  .contact-page {
    padding: 20px;
  }

  .main-content {
    margin-top: 40px;
  }

  .content-block {
    padding: 24px;
  }

  h1 {
    font-size: 2em;
    margin-top: 20px;
  }

  p {
    font-size: 1em;
  }

  .contact-info {
    grid-template-columns: 1fr;
    gap: 16px;
    margin: 24px 0;
  }

  .contact-card {
    padding: 20px;
  }

  .success-message-container {
    min-height: 50vh;
  }

  .success-content {
    padding: 30px 16px;
  }

  .success-icon {
    font-size: 48px;
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .contact-page {
    padding: 16px;
  }

  .main-content {
    margin-top: 30px;
  }

  .content-block {
    padding: 20px;
  }

  .contact-card {
    padding: 16px;
  }

  .success-content {
    padding: 24px 16px;
  }

  .success-icon {
    font-size: 40px;
    margin-bottom: 16px;
  }
}
