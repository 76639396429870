.current-settings {
 background-color: transparent; /* or set to match the parent background */
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: max-height 0.3s ease, opacity 0.3s ease;
  /* Reduced height by 30% */
  max-height: 70%;
  overflow: hidden;
  /* Ensuring the component looks like one solid block */
  box-sizing: border-box;
  /* Optional: Add a border if needed */
  border: none;
  /* Hide the component when hidden */
  display: block;
  box-shadow: none;
  width: 100%;


}

.current-settings h2 {
  text-align: center;
  margin-bottom: 25px;
  font-family: 'Poppins', sans-serif;
  font-size: 1.5em;
  color: #222;
  font-weight: 700;
}

.setting-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px; /* Reduced margin */
}

.switch-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.toggle-line {
  flex: 1;
  height: 1px;
  background-color: #e0e0e0;
  margin: 0 10px;
}

.person-buttons {
  display: flex;
  gap: 10px;
}

.person-buttons button {
  padding: 8px 12px;
  border: 1px solid #6200ea;
  border-radius: 20px;
  background-color: #fff;
  color: #6200ea;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.person-buttons button.active,
.person-buttons button:hover {
  background-color: #6200ea;
  color: #fff;
}

.budget-label {
  flex: 0 0 30%;
  text-align: left;
  margin-right: 10px;
  font-weight: 500;
  color: #444;
}

.budget-input {
  flex: 1;
  display: flex;
  gap: 10px;
}

.budget-input input {
  flex: 1;
  padding: 8px 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: white;
  text-align: center;
  font-size: 1em;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.budget-input input:focus {
  outline: none;
  border-color: #6200ea;
  box-shadow: 0 0 0 2px rgba(98, 0, 234, 0.2);
}

.budget-input select {
  padding: 8px 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: white;
  font-size: 1em;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.budget-input select:focus {
  outline: none;
  border-color: #6200ea;
  box-shadow: 0 0 0 2px rgba(98, 0, 234, 0.2);
}

/* Reduce the overall height */
.current-settings {
  height: auto; /* Let content determine height */
  max-height: 300px; /* Adjust as needed for 30% reduction */
  overflow-y: auto;
}

/* Hide the component when hidden */
.current-settings.hidden {
  display: none;
}
