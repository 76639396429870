.terms-of-service-page {
    display: flex;
    flex-direction: column;
    flex: 1;
    font-family: 'Inter', sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    line-height: 1.6;
    background-color: #fafafa;
    color: #333;
    min-height: 100vh;
  }
  
  .main-content {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 60px;  /* This ensures proper spacing below navbar */
    gap: 40px;
  }
  
  .content-block {
    background-color: #fff;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    padding: 40px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
  }
  
  .content-block h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 3em;
    margin-bottom: 20px;
    color: #222;
    font-weight: 700;
  }
  
  .content-block p {
    font-size: 1.15em;
    margin-bottom: 24px;
    color: #555;
    line-height: 1.6;
  }
  
  .content-block ul {
    list-style-type: none;
    padding: 0;
    margin: 0 0 24px 0;
  }
  
  .content-block ul li {
    font-size: 1.15em;
    color: #555;
    margin-bottom: 12px;
    padding-left: 24px;
    position: relative;
  }
  
  .content-block ul li:before {
    content: "•";
    position: absolute;
    left: 0;
    color: #6200ea;
  }
  
  /* Media Queries */
  @media (max-width: 768px) {
    .about-page {
      padding: 20px;
    }
  
    .main-content {
      flex-direction: column;
    }
    
    .content-block {
      padding: 20px;
      max-width: 100%;
      width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .content-block h1 {
      font-size: 2em;
    }
  
    .content-block p,
    .content-block ul li {
      font-size: 1em;
    }
  }
  