.landing-page {
  display: flex;
  flex-direction: column;
  flex: 1; /* Allows the landing-page to expand and fill available space */
  font-family: 'Inter', sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  line-height: 1.6;
  background-color: #fafafa;
  color: #333;
}

.main-content {
  flex: 1; /* Allows main content to expand and fill available space */
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 60px;
  gap: 40px;
}

.landing-page .content-block {
  background-color: #fff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  overflow: hidden;
}

.landing-page .left-column {
  flex: 0 0 50%;
  max-width: 600px;
  padding: 40px;
}

.landing-page .right-column {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.image-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.illustration {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.landing-page .left-column h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 3em;
  margin-bottom: 20px;
  color: #222;
  font-weight: 700;
}

.landing-page .left-column p {
  font-size: 1.15em;
  margin-bottom: 30px;
  color: #555;
}

.form-group {
  margin-bottom: 24px;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #444;
}

input:not([type="checkbox"]),
select {
  width: 100%;
  max-width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1em;
  background-color: #fff;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 48px;
}

select {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%3333' d='M10.293 3.293L6 7.586 1.707 3.293A1 1 0 00.293 4.707l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  padding-right: 36px;
}

input:focus,
select:focus {
  outline: none;
  border-color: #6200ea;
  box-shadow: 0 0 0 4px rgba(98, 0, 234, 0.2);
}

.landing-page .advanced-settings {
  color: #6200ea;
  text-decoration: none;
  font-size: 0.95em;
  transition: color 0.3s ease;
}

.landing-page .advanced-settings:hover {
  color: #5a00d1;
}

.landing-page .advanced-settings {
  margin-top: 20px;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
}

.landing-page .cta-button {
  background-color: #6200ea;
  color: #fff;
  padding: 14px 28px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 1.1em;
  font-weight: 600;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: auto;
  min-width: 200px;
  box-shadow: 0 4px 6px rgba(98, 0, 234, 0.2);
  position: relative;
  overflow: hidden;
}

.landing-page .cta-button:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.3), transparent);
  transform: translateX(-100%);
  transition: 0.6s;
}

.landing-page .cta-button:hover {
  background-color: #5000c9;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(98, 0, 234, 0.3);
}

.landing-page .cta-button:hover:before {
  transform: translateX(100%);
}

.landing-page .cta-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(98, 0, 234, 0.2);
}

.landing-page .cta-button i {
  margin-right: 10px;
  font-size: 1.2em;
}

@media (max-width: 768px) {
  .landing-page .main-content {
    flex-direction: column;
  }
  
  .landing-page .content-block {
    padding: 20px;
  }
  
  .landing-page .left-column,
  .landing-page .right-column {
    max-width: 100%;
    width: 100%;
  }

  .landing-page .cta-button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .landing-page .main-content .left-column h1 {
    font-size: 2em;
  }

  .landing-page .main-content .left-column p {
    font-size: 1em;
  }
}

.city-autocomplete {
  position: relative;
}

.input-wrapper {
  position: relative;
}

.clear-button {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 24px;
  line-height: 1;
  cursor: pointer;
  color: #ccc;
  transition: color 0.3s ease;
  padding: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clear-button.active {
  color: #333;
}

.clear-button:hover {
  color: #666;
}

.suggestions-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 0 0 8px 8px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  text-align: left;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.suggestions-list li {
  padding: 10px 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.suggestions-list li:hover {
  background-color: #f0f0f0;
}

.advanced-settings-link {
  color: #6200ea;
  text-decoration: none;
  font-size: 0.95em;
  transition: color 0.3s ease;
}

.advanced-settings-link:hover {
  color: #5a00d1;
}

.advanced-settings-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.advanced-settings-content {
  background-color: white;
  padding: 24px;
  border-radius: 16px;
  position: relative;
  max-width: 400px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
}

.close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  transition: color 0.3s ease;
  padding: 0;
  line-height: 1;
}

.close-button:hover {
  color: #333;
}

.advanced-settings-button {
  background: none;
  border: none;
  color: #6200ea;
  font-size: 1rem;
  cursor: pointer;
  transition: color 0.3s ease;
}

.advanced-settings-button:hover {
  color: #5000c9;
}

/* New Styles for Travel Around the World */
.travel-around-world {
  background-color: #fff;
  padding: 60px 20px;
  text-align: center;
  margin-top: 40px;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
}

.travel-around-world h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 2.5em;
  margin-bottom: 40px;
  color: #222;
  font-weight: 700;
}

.travel-images {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
}

.travel-images img {
  width: 45%;
  border-radius: 12px;
  object-fit: cover;
}

.how-it-works {
  background-color: #fafafa;
  padding: 60px 20px;
  text-align: center;
  margin-top: 40px;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
}

.how-it-works h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 2.5em;
  margin-bottom: 40px;
  color: #222;
  font-weight: 700;
}

.steps {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 40px;
}

.step {
  background-color: #fff;
  padding: 24px;
  flex: 1 1 22%;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  text-align: left;
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.step i {
  font-size: 1.5em;
  color: #000000;
  margin-top: 2px;
}

.step h3 {
  font-size: 1.1em;
  color: #555;
  margin: 0;
  flex: 1;
}

.explore-with-us {
  background-color: #fff;
  padding: 60px 20px;
  text-align: center;
  margin-top: 40px;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
}

.explore-with-us h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #222;
  font-weight: 700;
}

.explore-with-us p {
  font-size: 1.1em;
  margin-bottom: 30px;
  color: #555;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

/* Subscription Form Styling */
.subscription-form {
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
}

.subscription-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.input-button-group {
  display: flex;
  gap: 12px;
  width: 100%;
}

.input-button-group input[type="email"] {
  flex: 1;
  min-width: 0;
}

.consent-wrapper {
  width: 100%;
  margin-top: 8px;
}

.consent-label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  margin: 0;
  text-align: left;
}

.consent-label input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #6200ea;
  border-radius: 4px;
  margin: 0;
  cursor: pointer;
  position: relative;
  background-color: white;
  flex-shrink: 0;
  padding: 0;
  margin-top: 2px;
}

.consent-label input[type="checkbox"]:checked {
  background-color: #6200ea;
}

.consent-label input[type="checkbox"]:checked::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: translate(-50%, -60%) rotate(45deg);
}

.consent-text {
  font-size: 0.85em;
  color: #666;
  line-height: 1.4;
  flex: 1;
}

/* Add hover and focus states for better UX */
.consent-label input[type="checkbox"]:hover {
  border-color: #5000c9;
}

.consent-label input[type="checkbox"]:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(98, 0, 234, 0.2);
}

.consent-text {
  font-size: 0.85em;
  color: #666;
  line-height: 1.4;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .input-button-group {
    flex-direction: column;
  }

  .subscription-form .subscribe-button {
    width: 100%;
  }

  .subscription-wrapper {
    gap: 16px;
  }

  .consent-label {
    align-items: flex-start;
    padding: 0 4px;
  }

  .consent-label input[type="checkbox"] {
    margin-top: 2px;
  }

  .consent-text {
    margin-top: 0;
  }
}

/* Update existing subscribe button styles */
.subscribe-button {
  white-space: nowrap;
  padding: 0 28px;
  height: 48px;
  min-width: auto;
}

.subscription-form.consent-error .consent-group label {
  animation: blink 1s;
}

.subscription-form .form-group {
  flex: 1 1 300px;
  max-width: 400px;
  margin-bottom: 0;
}

.subscribe-button {
  background-color: #6200ea;
  color: #fff;
  padding: 0 28px;
  border: none;
  border-radius: 8px; /* Match input border-radius */
  cursor: pointer;
  font-size: 1.1em;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(98, 0, 234, 0.2);
  height: 48px; /* Match input height */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
}

.subscribe-button:hover {
  background-color: #5000c9;
  box-shadow: 0 6px 12px rgba(98, 0, 234, 0.3);
}

.subscribe-button:active {
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(98, 0, 234, 0.2);
}

/* New Styles for Confirmation Checkbox and Error Animation */
.consent-group {
  display: flex;
  align-items: center;
  gap: 8px;
}

.consent-group input[type="checkbox"] {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.consent-group label {
  font-size: 0.85em; /* Made text smaller */
  color: #777; /* Made text lighter */
  cursor: pointer;
}

/* Blinking Animation */
@keyframes blink {
  0% { color: #777; }
  25% { color: #6200ea; }
  50% { color: #777; }
  75% { color: #6200ea; }
  100% { color: #777; }
}

.consent-error {
  border-color: #6200ea;
}

.success-message {
  color: green;
  margin-top: 10px;
}

.error-message {
  color: red;
  margin-top: 10px;
}

/* Travel Images Container */
.travel-images-container {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-bottom: 30px;
}

.travel-image-wrapper {
  flex: 1;
  max-width: 45%;
}

.travel-image-wrapper h3 {
  font-family: 'Poppins', sans-serif;
  font-size: 1.5em;
  color: #333;
  margin-bottom: 16px;
  font-weight: 600;
}

.travel-image-wrapper img {
  width: 100%;
  border-radius: 12px;
  object-fit: cover;
}

/* Adjustments for Subscription Form */
.subscription-form {
  flex-direction: column; /* Default to column for mobile */
}

@media (min-width: 769px) {
  .subscription-form {
    flex-direction: row; /* Row for desktop */
    align-items: center;
  }

  /* Position checkbox and text below the button on desktop */
  .subscription-form .consent-group {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .travel-images-container {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .travel-image-wrapper {
    max-width: 80%;
  }
}

@media (max-width: 480px) {
  .travel-image-wrapper {
    max-width: 100%;
  }
  
  .travel-image-wrapper h3 {
    font-size: 1.3em;
  }
}

/* Update the consent error animation */
.subscription-form.consent-error .consent-text {
  animation: blink 2s;
  animation-iteration-count: 1;
}

@keyframes blink {
  0% { color: #666; }
  25% { color: #6200ea; }
  50% { color: #666; }
  75% { color: #6200ea; }
  100% { color: #666; }
}

/* Add these styles at the end of the file */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

.success-modal {
  background-color: white;
  padding: 32px;
  border-radius: 16px;
  position: relative;
  max-width: 400px;
  width: 90%;
  animation: slideIn 0.3s ease-in-out;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.success-modal-content {
  text-align: center;
}

.success-icon {
  font-size: 48px;
  color: #4CAF50;
  margin-bottom: 16px;
}

.success-modal h3 {
  font-family: 'Poppins', sans-serif;
  font-size: 1.5em;
  margin-bottom: 12px;
  color: #222;
}

.success-modal p {
  color: #666;
  margin-bottom: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.travel-description {
  margin-bottom: 40px; /* Increase this value to add more space above the button */
  max-width: 800px; /* Optional: limit the width of the description text */
  margin-left: auto;
  margin-right: auto;
  line-height: 1.6;
  color: #555;
}

